import React from 'react';
import { bindings, hook } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import * as Yup from 'yup';
import valid from 'card-validator';
import { normalizeNumber } from '@vl/mod-utils/currencyFormatter';
import _ from 'lodash';

Yup.addMethod(Yup.string, 'creditCardType', function (errorMessage) {
  return this.test('test-card-type', errorMessage, function (value) {
    const { path, createError } = this;
    const numberValidation = valid.number(value);

    return numberValidation.isPotentiallyValid || createError({ path, message: errorMessage });
  });
});

Yup.addMethod(Yup.string, 'creditCardExpiry', function (errorMessage) {
  return this.test('test-card-expiry', errorMessage, function (value) {
    const { path, createError } = this;
    const numberValidation = valid.expirationDate(value);
    return numberValidation.isValid || createError({ path, message: errorMessage });
  });
});
Yup.addMethod(Yup.string, 'creditCardCVC', function (errorMessage) {
  return this.test('test-card-cvc', errorMessage, function (value) {
    const { path, createError } = this;
    const numberValidation = valid.cvv(value, 3);
    return numberValidation.isValid || createError({ path, message: errorMessage });
  });
});

Yup.addMethod(Yup.string, 'creditCardLength', function (errorMessage) {
  return this.test('test-card-length', errorMessage, function (value) {
    const { path, createError } = this;
    return (value && value.length === 16) || createError({ path, message: errorMessage });
  });
});

Yup.addMethod(Yup.string, 'moreThanMoney', function (minVal, errorMessage) {
  return this.test('test-more-than-money', errorMessage, function (value) {
    const { path, createError } = this;
    return normalizeNumber(value) >= normalizeNumber(minVal) || createError({ path, message: errorMessage });
  });
});

Yup.addMethod(Yup.string, 'maxValue', function (val, errorMessage) {
  return this.test('test-more-max-value-money', errorMessage, function (value) {
    const { path, createError } = this;
    return normalizeNumber(value) <= normalizeNumber(val) || createError({ path, message: errorMessage });
  });
});

Yup.addMethod(Yup.string, 'voucherCode', function (errorMessage) {
  return this.test('test-voucher-code', errorMessage, function (value) {
    const { path, createError } = this;
    const val = _.toString(value);
    return val.length < 8 || createError({ path, message: errorMessage });
  });
});

export const ValidateProvider = bindings({
  ValidateProvider: {
    rules: [
      [
        'data',
        {
          data: {
            ValidateHandler: hook(() => {
              return {
                Yup,
              };
            }),
          },
        },
      ],
    ],
  },
})(({ children }) => <DIV className="ValidateProvider">{children}</DIV>);

export default ValidateProvider;
