import _ from 'lodash';
import path from 'path';

const store = {};
export const loader = {};

_.extend(loader, {
  set: (lang, content) => {
    _.set(store, [lang], content);
  },
  get: (lang) => {
    if (!loader.has(lang)) {
      _.set(store, lang, new Promise((resolve, reject) => {
        loader.load(lang).then((res) => {
          const data = res;
          loader.set(lang, data);
          resolve(data);
        }).catch((err) => {
          // console.log('error', err);
          loader.set(lang, null);
          resolve(null);
        });
      }));
    }
    return _.get(store, [lang]);
  },
  has: (lang) => {
    return _.has(store, [lang]);
  },
  getStore: () => {
    return store;
  },
  load: (lang) => {
  }
});
