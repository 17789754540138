const _ = require('lodash');
const moment = require('moment');
const { later } = require('@vl/mod-utils/later');

exports.formatDuration = ($duration, format = '') => {
  const allPart = [];
  const day = $duration.days();
  day && allPart.push(`${day}d`);
  allPart.push(`${$duration.hours()}h`);
  allPart.push($duration.minutes());
  allPart.push($duration.seconds());
  const rtn = [];
  for (let index = 0; index < allPart.length; index++) {
    const indexVal = parseInt(allPart[index]);
    if (indexVal) {
      rtn.push(allPart[index]);
      if (index + 1 < allPart.length) {
        rtn.push(allPart[index + 1]);
      }
      break;
    }
  }

  while (rtn.length < 2) {
    rtn.unshift(0);
  }
  return rtn.map((item) => _.padStart(`${item}`, 2, '0')).join(':');
};

exports.formatCallDuration = (duration, format = '') => {
  const min = _.ceil(duration / 60);
  return `${min} phút`;
};

exports.formatCallDurationWithI18n = (i18n) => (duration) => {
  const min = _.ceil(duration / 60);
  return i18n.t('Course.Info.minute', { count: min });
};

exports.formatSessionOccurence = (session) => {
  if (parseInt(session) === 100000) {
    return 'Không giới hạn buổi';
  }
  return `${session} buổi`;
};

exports.formatAttendeeWithI18n = (i18n) => (count) => {
  return i18n.t('RemoteConfig.Room.student', { count });
};

exports.formatSessionOccurenceWithI18n = (i18n) => (session) => {
  if (parseInt(session) === 100000) {
    return i18n.t('RemoteConfig.Course.Purchase.sessionOccurence.unlimited');
  }
  return i18n.t('RemoteConfig.Course.Purchase.sessionCount', { count: session });
};

const sessionItem = (i18n) => (itemData) => {
  const start_at = _.get(itemData, 'start_at');
  const type = _.get(itemData, 'type');
  const repeat_pattern = _.get(itemData, 'repeat_pattern');
  const time = moment(start_at)
    .locale(i18n.locale)
    .utcOffset(i18n.utcOffset)
    .format(i18n.locale === 'vi' ? 'DD/MM hh:mm A' : 'dddd, MMM Do hh:mm A');

  if (!repeat_pattern) {
    return {
      type: 'single',
      data: [time],
    };
  }
  const schedule = later.parse.cron(repeat_pattern);
  const dateOrdering = _.get(schedule, 'schedules.0.d');
  let dayRepeat = _.map(dateOrdering, (item) =>
    moment()
      .locale(i18n.locale)
      .day(item - 1)
      .format('dddd')
  );
  const result = {
    type,
    data: [
      `${i18n.t('Course.DurationSetting.every_week')} ${dayRepeat.join(', ')} ${i18n.t(
        'Course.DurationSetting.start_at'
      )} ${time}`,
    ],
  };

  return result;
};

exports.formatSessionSlotTime = (i18n) => (sessions) => {
  const arr = _.compact(_.map(sessions, (session) => sessionItem(i18n)(session)));
  return arr.map((ele) => _.get(ele, 'data.0')).join(',');
};

exports.TIMESTAMPTZ_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSS+00:00';

exports.DATE_TIME_FORMAT = 'HH:mm:ss - DD/MM/yyyy';
exports.TIME_HOUR_MINUTE_FORMAT = 'hh:mm A';
exports.START_TIME_HOUR_MINUTE_FORMAT = 'hh:mm A';
exports.END_TIME_HOUR_MINUTE_FORMAT = 'hh:mm A';
exports.END_TIME_HOUR_MINUTE_FULL_FORMAT = 'hh:mm DD/MM';
exports.DATE_TIME_CREATED_FORMAT = {
  de: 'HH:mm - DD MMM YYYY',
  vi: 'hh:mm A - DD MMM, YYYY',
  en: 'hh:mm A - MMM DD, YYYY',
};

exports.END_TIME_HOUR_MINUTE_FULL_ML_FORMAT = {
  de: 'HH:mm DD/MM',
  vi: 'HH:mm DD MMM',
  en: 'hh:mm A - DD/MM',
};

exports.TIME_HOUR_MINUTE_ML_FORMAT = {
  de: 'HH:mm',
  en: 'hh:mm A',
};

exports.START_DATE_ML_FORMAT = {
  de: 'DD/MM',
  en: 'MM/DD',
};

exports.START_DATE_YEAR_ML_FORMAT = {
  de: 'DD/MM/YYYY',
  en: 'YYYY/MM/DD',
};

exports.CREATED_DATE_FORMAT = {
  vi: 'DD MMM, YYYY',
  en: 'Do MMMM, YYYY',
};

exports.DATE_FORMAT = {
  vi: 'DD MMM, YYYY',
  en: 'MMM DD, YYYY',
};

exports.START_TIME_FORMAT = 'ddd DD/MM HH:mm';
exports.START_TIME_FORMAT_TIME_ZONE = {
  vi: 'dddd, DD MMM, YYYY - hh:mm A',
  en: 'dddd, MMM DD, YYYY - HH:mm A',
  de: 'dddd, MMM DD, YYYY - HH:mm',
};

exports.START_TIME_FORMAT_TIME_ZONE_FULL = {
  vi: 'dddd, DD MMM YYYY - hh:mm A',
  en: 'dddd, MMMM Do YYYY - hh:mm A',
  de: 'dddd, DD MMMM YYYY - HH:mm',
};

exports.START_TIME_FULL_FORMAT = 'dddd DD/MM HH:mm';
exports.START_DAY_FULL_FORMAT = 'dddd DD/MM';

exports.START_DATE_FORMAT = 'DD/MM';
