import _ from 'lodash';

const refs = {};

export const Glightbox = {
  apply: _.debounce((options) => {
    if (window.GLightBox) {
      if (!refs.instance) {
        const instance = window.GLightBox({
          selector: '.glightbox',
          ...options,
          zoomable: false,
          draggable: false,
          preload: false,
        });
        refs.instance = instance;
      }
      refs.instance.reload();
    } else {
      console.log('window.GLightBox is not loaded');
    }
  }, 1000),

  getInstance: () => refs.instance,

  on: (...args) => {
    if (refs.instance) {
      // console.log('bind event');
      return refs.instance.on(...args);
    }
    // timeout to retry
    setTimeout(() => {
      // console.log('retry glightbox');
      Glightbox.on(...args);
    }, 1000);
    // console.log('glightbox is not inited');
  },
  once: (...args) => {
    if (refs.instance) {
      return refs.instance.once(...args);
    }
    // console.log('glightbox is not inited');
  },

  applyPlayer: (id) => {
    setTimeout(() => {
      const playerEle = document.getElementById(`stream-player-${id}`);
      if (playerEle && window.Stream) {
        const player = window.Stream(playerEle);
        if (player) {
          const onError = () => {
            window.retryVideoUrl && window.retryVideoUrl(playerEle);
          };
          player.addEventListener('error', onError);
          player.addEventListener('canplay', () => {
            window.clearRetryVideoUrl && window.clearRetryVideoUrl(playerEle);
            player.removeEventListener('error', onError);
          });
        }
      }
    }, 100);
  },
};

export default Glightbox;
