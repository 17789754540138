module.exports = {
  welcome: 'Willkommen',
  takeTheTour: 'Einführung',
  Next: 'Weiter',
  Skip: 'Überspringen',
  Cancel: 'Beenden',
  Show: 'Anzeigen',
  Hide: 'Verbergen',
  SeeMore: 'Mehr sehen',
  noData: 'Keine Daten',
  Partner: {
    title: 'Werde heute ein Partner',
    description: 'Statt zu faulenzen, nutze deine Zeit effectiv, um dein Wissen zu teilen und dabei Geld zu verdienen! Registriere dich, um jetzt von einmaligen Vorteilen zu profitieren',
  },
  WordDigit: ['null', 'eins', 'zwei', 'drei', 'vier', 'fünf', 'sechs', 'sieben', 'acht', 'neun'],
  WordMoney: ['million', 'tausend'],
  WordCurrency: {
    hundred: 'hundert',
    old: '',
    ten: 'zehn',
    tenPrefix: 'zehn',
    onePostfix: 'eins',
    fivePostfix: 'fünf',
  },
  Currency: {
    vnd: 'vietnamesische Dong',
  },
  Form: {
    required: 'Pflichtfeld',
    BackBtnTxt: 'Zurück',
    CancelBtnTxt: 'Beenden',
    SaveBtnTxt: 'Speichern',
    DeleteBtnTxt: 'Löschen',
    EditBtnTxt: 'Edit',
  },
  Weeks: {
    sunday: 'Sonntag',
    monday: 'Montag',
    tuesday: 'Dienstag',
    wednesday: 'Mittwoch',
    thursday: 'Donnerstag',
    friday: 'Freitag',
    saturday: 'Samstag',
  },
  Welcome: {
    welcomeTitle1: 'Willkommen bei Unitz',
    welcomeDesc1: 'Der beste Platz, um zertifizierte Anleiter und Mentoren für verschiedenste Fachgebiete zu finden',
    welcomeTitle2: 'Transparente Preisbildung',
    welcomeDesc2: 'Feste Sitzungspreise lassen sie stets sorgenfrei mit Unitz lernenW',
    welcomeTitle3: 'Eine auf Vertrauen basierende Community',
    welcomeDesc3: 'Lies die Anleiter-Bewertungen anderer Lerner, befor du deine Entscheidung triffst',
    or: 'oder',
    exploreNow: 'Entdecke jetzt',
    signupLabel: 'Account erstellen',
    choiceArea: 'Choose the field that interests you',
    choiceAreaDesc: 'You can change it in the Settings.',
    areaType: {
      education: 'Education',
      consultant: 'Consulting',
    },
    function: 'Choose the service',
    educationDesc: 'Select "Education" if you need to find online courses, learn face-to-face with teachers.',
    consultantDesc: 'Select "Consulting" if you need advice from experienced experts in many fields.',
  },
  GDPR: {
    consent: {
      noticeMessageTxt: `Durch das Klicken auf "Cookies akzeptieren" stimmen Sie der Verwendung von Cookies auf unser Webseite zu.`,
      acceptButtonText: 'Alle akzeptieren ',
      declineButtonText: 'Alle ablehnen',
    },
  },
  Setting: {
    Language: {
      yourLanguage: 'Deine Sprache',
      note: 'Immer wenn du dich bei Unitz einloggst, ist dies deine Anzeigesprache. Die Einstellung hat keinen Einfluss auf die Sprache die den Lernern angezeigt wird.',
      language: 'Sprache',
      language_placeholder: 'wähle eine Sprache',
      save: 'Speichern',
      Notification: {
        success: 'Änderung erfolgreich',
        fail: 'Änderung fehlgeschlagen',
      },
    },
    DeactiveAccount: {
      title: 'Account pausieren / Account löschen',
      description: 'Pausiere deinen Account bei Unitz zeitweilig oder lösche deinen Account vollständig',
      deleteConfirm: 'Bestätige die Löschung deines Accounts',
      deactiveConfirm: 'Bestätige die Pausierung deines Accounts',
      deleteNote: 'Bist du sicher, dass du deinen Account löschen willst? Nach der Löschung deines Accounts wird es nicht mehr möglich sein, dich bei Unitz einzuloggen.',
      deactiveNote: 'Bist du sicher, dass du deinen Account pausieren willst? Wärend der Pausierung ist es nicht möglich, die Dienste von Unitz zu nutzen.',
      Action: {
        activate: 'Account aktivieren',
        deactivate: 'Account pausieren',
        delete: 'Account löschen',
        confirm: 'Bestätigen',
        cancel: 'Beenden',
      },
      Notification: {
        deleteSuccess: 'Dein Account wurde gelöscht',
        activeSuccess: 'Dein Account wurde aktiviert',
        deactiveSuccess: 'Dein Account wurde pausiert',
        error: 'etwas ist schiefgelaufen...',
      },
    },
  },
  Selection: {
    titleSearchBar: 'Finde deinen Anleiter',
    titleCourseSearchBar: 'Suche hier nach deinem Kurs...',
    call: 'Anruf',
    video: 'Video',
    message: 'Nachricht',
    search: 'Suchen',
    noApply: 'nicht anwendbar',
    placeHolder: 'Beispiel: Englisch, Business',
    vnd: 'đ',
    filterDetail: 'Detail-Filter',
    vote: 'Rate',
    priceSlashMinute: 'Preis/Minute',
    bestFit: 'am besten passend',
    result: 'Anleiter gefunden',
    nextPage: 'nächste Seite',
    prevPage: 'zurück',
  },
  Login: {
    welcomeTitle1: 'Willkommen bei Unitz!',
    loginOptionsTitle1: 'Benutzer-Anmeldung',
    brandDesc: 'Mit Unitz findest du Anleiter, denen du vertrauen kannst, in Bereichen, die dich interessieren, zu eienem festen Preis.',
    email: 'Email-Adresse',
    guestName: 'Name eingeben (Gast)',
    password: 'Passwort',
    forgotPassword: 'Passwort vergessen?',
    saveAccount: 'Account speichern',
    or: 'oder',
    guestLogin: 'Gast-Zugang',
    login: 'Einloggen',
    logout: 'Ausloggen',
    signupQuestion: 'Du hast noch keinen Account?',
    signupRedirect: 'Melde dich jetzt an',
    loginLabel: 'Anmeldung mit',
    signupLabel: 'Neuen Account erstellen',
    modal: {
      title: 'Einloggen um fortzufahren',
    },
    ChoosingAdvisorTitle: 'ausgewählter Anleiter',
    facebookLogin: 'Anmeldung mit Facebook-Account',
    googleLogin: 'Anmeldung mit Google-Account',
    appleLogin: 'Anmeldung mit Apple-Account',
    emailLogin: 'Anmeldung mit Email-Addresse',
    method: 'Wie möchtest du dich anmelden? Bitte auswählen',
  },

  Auth: {
    login: 'Anmelden',
    register: 'Registrieren',
  },

  Signup: {
    createAccount: 'Account erstellen',
    signupOptionsTitle1: 'Anmelden mit',
    loginLabel: 'Einloggen mit',
    email: 'E-Mail-Adresse',
    fullname: 'Vor- und Nachname',
    phone: 'Telefonnummer',
    password: 'Passwort',
    passwordConfirm: 'Passort wiederholen',
    forgotPassword: 'Passwort vergessen?',
    or: 'oder',
    message: 'Ihre Nachricht',
    nextStep: 'Weiter',
    signup: 'Anmelden',
    loginQuestion: 'Du hast schon ein Account? ',
    loginRedirect: 'Jetzt anmelden',
    loginToRegisterCourse: 'Log dich ein um dich für einen Kurs zu registrieren',
    verifyEmailTitle: 'Verify your email',
    verifyEmailSuccessStatus: 'Your email has been verified. You can now sign in with your new account',
    verifyEmailSuccessContinue: 'Continue',
    signupLabel: 'Anmelden mit',
    agreeLabel1: 'Durch deine Anmeldung bestätigst du, dass du über 13 Jahre alt bist.',
    agreeLabel1_1: 'Mit deiner Anmeldung bestätigst dul,',
    agreeLabel1_2: 'das du über 13 Jahre alt bist',
    agreeLabel1_3: ', ',
    agreeLabel2: 'und',
    agreeLabel3: '',
    termOfServices: 'Geschäftsbedingungen',
    privacy: 'Datenschutz-Bestimmungen',
    description: 'Erstelle jetzt einen Account, um mit unseren Anleitern und Lehrern Kontakt aufzunehmen.',
    descriptionAdvisor: 'Erstelle jetzt einen Account, damit die Benutzer mit dir Kontakt aufnehmen können.',
    signupWithFb: 'Weiter mit Facebook-Account',
    signupWithGoogle: 'Weiter mit Google-Account',
    signupWithEmail: 'Anmelden mit eigener Email',
    signupWithApple: 'Weiter mit Apple-ID',
    termsAndCondition: 'Geschäftsbedingungen',
    signupNotification: 'Du hast dich erfolgreich registriert',
    labelTermsContract: 'Untenstehend findest du den Kooperationsvertrag. Bitte lies dir diesen Vertrag gründlich durch und bestätige deine Einwilligung durch die Eingabe deines Namens darunter',
    labelConfirm: 'Hiermit bestätige ich, dass meine Angaben korrekt und wahrheitsgemäß sind und ich verantwortlich für die angegebenen Inforationen bin.',
    readConfirm: 'Hiermit bestätige ich, dass ich die allgemeinen Geschäftsbedingungen gelesen habe und dass ich diesen zustimme.',
    requireFill: '* Um einen Vertrag abzuschließen, trage bitte deine Informationen auf Seite B ein',
    check_get_noti: 'I agree to receive product updates and marketing communications from Unitz.',
    saveAccount: 'Lưu tài khoản',
  },
  Home: {
    welcomeBack: 'Willkommen zurück',
    recommendedAdvisors: 'Deine Anleiter',
    interestedAdvisors: '',
    more: 'mehr anzeigen',
    recommendedForYou: 'empfohlene Anleiter',
    featuredCourses: 'Deine Kurse',
    reactionAdvisor: 'In deiner History',
    research: 'Suche',
    categoryInterested: 'Lieblingskategorien',
    edit: 'Einstellungen',
    listNoAnswer: 'Du hast %{num} unbeantwortete Anrufe',
    callNow: 'Jetzt anrufen',
    exitApp: 'Erneut ntippen, um die App zu verlassen',
    consultant: 'Berater',
    academic: 'Academiker',
  },
  HomeEducation: {
    recommendedAdvisors: 'Deine Lehrer',
    requestLesson: 'KOSTENLOSE PROBESTUNDE VEREINBAREN',
    accountNotFinish: 'Tài khoản của bạn chưa hoàn tất thiết lập, vui lòng hoàn tất để xác thực.',
    settingAccount: 'Jetzt einrichten!',
    listCategory: 'Kategorien',
    seeAll: 'Alle anzeigen',
  },
  Chat: {
    image: 'Bild',
    status: {
      Online: 'Online',
      Offline: 'Offline',
    },
    seen: 'gesehen',
  },
  Admin: {
    Dashboard: {
      teacher: 'Lehrer',
      advisor: 'Anleiter',
      user: 'Benutzer',
      courseOnProgress: 'Laufende Kurse',
      IncomeTeacherInYear: 'Monatliches Einkommen der Lehrer',
      IncomeTeacherPopup: 'Dieser Wert stellt einen Vergleich des Einkommens der Lehrer zwischen 2021 und 2022 dar',
      IncomeSystemInYear: 'Monatliches Einkommen der Plattform',
      IncomeSystemPopup: 'Dieser Wert stellt einen Vergleich des Einkommens der Platform zwischen 2021 und 2022 dar.',
      courseInDay: 'Kurse von Heute',
      detail: 'Detail',
      category: 'Categories',
      course: 'Courses',
      student: 'Students',
    },
    Account: {
      listTxt: 'Anleiter-Liste',
      Status: {
        active: 'Aktiv',
        inActive: 'Inaktiv',
        activate: 'Aktivieren',
        deActivate: 'Deaktivieren',
        deleted: 'Gelöschen',
        notDeletedYet: 'None',
        deactive: 'Vô hiệu hóa',
      },
      Table: {
        displayName: 'Name',
        email: 'Email',
        createdAt: 'Erstellt bei',
        contact: 'Kontakt',
        note: 'Notiz',
        phoneNumber: 'Telefonnummer',
        passedDate: 'Ablaufdatum',
        startAt: 'Beginn am',
        endAt: 'Ende am',
        status: 'Status',
        courseName: 'Kursname',
        roomName: 'Raumname',
        studentList: 'Student',
        joinTime: 'Beitrittsdatum',
        urlList: 'Url-Liste',
        room: 'Raum',
        type: 'Typ',
        link: 'Link',
        course: 'Kurs',
        profile: 'Profil',
        advisor: 'Anleiter',
        scoring_int: 'Scores',
        contract: 'Vertrag',
        referral: 'Verweis',
        topic: 'Topic',
        literacy: 'Level',
        targetAudience: 'Target',
        numberOfStudents: 'Number of students',
        numberOfStudent: 'Số lượng',
      },
      Action: {
        getUrl: 'url bekommen',
        copy: 'Kopieren',
        openNewTab: 'Neuen Tab öffnen',
        downloadCSV: 'Profil herunterladen',
        delete: 'Löschen',
        restore: 'Wiederherstellen',
      },
    },
    Room: {
      detailRoom: 'Detail-Raum',
    },
    User: {
      Status: {
        active: 'Test-Status einschalten',
        deactive: 'Test-Status ausschalten',
      },
    },
    Voucher: {
      Info: {
        adminOnly: 'Diese Seite ist ausschließlich für Administratoren.',
        titleGenerateVoucher: 'Neuen Code generieren',
      },
      Action: {
        createNewVoucher: 'Neuen Gutschein generieren',
      },
    },
    Assist: {
      assistor: 'Assistor',
      assist: 'Assist',
      nonAssist: 'Non-assistance',
    },
    Messages: {
      title: 'Für',
      subject: 'Subjekt',
      targetType: {
        advisor: 'Anleiter',
        user: 'Benutzer',
      },
      Action: {
        edit: 'Bearbeiten',
      },
    },
  },
  Question: {
    questionTitle: 'FAQs',
    more: 'Mehr anzeigen',
    moreCommonQuestion: 'Mehr FAQs anzeigen',
    allQuestion: 'Alle Fragen',
  },
  Guideline: {
    guidelineTitle: 'Vier unkomplizierte Schritte, um zu beginnen.',
  },
  CustomerCarousel: {
    customerTitle: 'Empfehlungen',
  },
  Handbook: {
    handBookTitle: 'UNITZ Handbuch',
    detail: 'Handbuch Details',
  },
  Whyus: {
    whyUsTitle: 'Warum UNITZ',
  },
  Category: {
    categoryTxt: 'Kategorien',
    recommendedAdvisors: 'empfohlene Anleiter',
    interestedAdvisors: 'Außerdem interessant für dich',
    recommendedTeachers: 'empfolene Lehrer',
    more: 'Mehr anzeigen',
    discoverMore: 'Mehr entdecken',
    detail: 'Details',
    searchRecent: 'Zuletzt besuchte Kategorien',
    categoryTitle: 'Advise by methods',
    allCategory: 'Alle Kategorien',
    highlightCategory: 'Top Kategorien',
    moreCategories: 'Mehr Kategorien',
    suggestionCategoriesTitle: 'Entdecke Anleiter in anderen Kategorien',
    learnMore: 'Mehr dazu',
  },
  CategoryDetail: {
    categoryDetailTitle: 'Kategorie Details',
    searchTextPlaceholder: 'Suche',
    moreAdvisor: '%{total} weitere Anleiter verfügbar',
    expandFilters: 'Filter erweitern',
    collapseFilters: 'Filter zurücksetzen',
    recommendedCategories: 'empfohlene Kategorien',
  },
  JobPost: {
    title: 'Recruitment',
    Filter: {
      title: 'Nach Kategorien filtern:',
      categories: 'Kategorien',
      all: 'Alle Kategorien',
    },
    Detail: {
      applyThisJob: 'Um einen Job bewerben',
      apply: 'Bewerben',
      submit: 'Bewerbung abschicken',
    },
    Form: {
      title: 'Bewerbung einreichen',
      fullName: 'vollständiger Name',
      email: 'Email',
      phone: 'Telefonnummer',
      cv: 'Dein Lebenslauf',
      successfullNotification: 'Bewerbung abgeschickt',
      upload: 'Upload',
      errorNotification: 'Something went wrong. Please try again',
    },
    Validation: {
      required: 'Bitte füll dieses Feld aus',
      fileSize: 'Die Datei ist größer als 5MB',
      phone: 'Ungültige Telefonnummer',
    },
    Action: {
      startNow: 'Jetzt starten',
      contactUs: 'Kontaktiere uns',
    },
  },
  Advisor: {
    highlightAdvisorTitle: 'Empfohlene Anleiter',
    highestAdvisorTitle: 'Die Beliebtesten Anleiter',
    pricePerMin: '(%{currency})/%{unit}',
    priceUnit: '/minute',
    rating: 'Rezension',
    joinedSince: 'Beigetreten seit %{dateStr}',
    voteCount: 'Anzahl der Stimmen',
    timeJoined: 'Erfahrungen',
    yearsExperience: 'Erfahrung in Jahren',
    adviseCount: 'Anzahl der Anrufe',
    courseCount: 'Anzahl angebotener Kurse',
    call: 'Jetzt anrufen',
    callText: 'Sprachanruf',
    callVideoText: 'Videoanruf',
    watchMore: 'mehr anzeigen',
    fitWithYou: 'Dein Match',
    listY_newAdvisors: 'Neu',
    listY_highlightAdvisors: 'Empfohlene Anleiter',
    listY_onlineAdvisors: 'Online',
    listY_interestedAdvisors: 'Für dich auch von Interesse',
    online: 'Online',
    offline: 'Offline',
    busy: 'Beschäftigt',
    more: 'Mehr anzeigen',
    less: 'Weniger anzeigen',
    aboutMe: 'Über mich',
    onCourse: {
      zero: '0 Kurse',
      one: '1 Kurs',
      other: '%{count} Kurse',
    },
    discoverMore: 'Entdecke mehr',
  },
  AdvisorRegister: {
    PlaceHolder: {
      description: 'Beschreibe deine praktischen Erfahrungswerte',
    },
    step: 'Schritt',
  },
  AdvisorVideoStart: {
    callInfoTxt: 'Du hast %{duration} Minuten, um diesen Anleiter zu kontaktieren.',
    callPolicyTx: 'Dein Anruf erfolgt über eine gesicherte Verbindung. Unitz bewahrt kein Aufnahmen der Gespräche mit den Anleitern auf.',
    videoCallTxt: 'Videoanruf',
  },
  AdvisorVideoCall: {
    title: 'Anruf beendet',
    callStatus: {
      completedLabel: 'Der Anruf wurde beendet',
      declinedLabel: 'Der Anruf wurde abgelehnt',
      missedLabel: 'Der Anruf wurde nicht angenommen',
      earlyEndLabel: 'Der Anruf wurde vorzeitig beendet',
    },
    callVideo: 'Videoanruf-Paket',
    callVoice: 'Sprachanruf-Paket',
    userReaction: 'Warst du zufrieden mit dem Anruf?',
    rejectCallText: 'Wurde der Anruf unterbrochen?',
    missCallText: 'Verpasster Anruf',
    earlyEndCallText: 'Wurde dein Anruf vorzeitig beendet?',
    reCall: 'Rückruf',
    ringingStatusTxt: 'Verbindung zum Anleiter wird aufgebaut. Einen Moment, bitte',
    incommingStatusTxt: 'Eingehender Videoanruf',
    initingStatusTxt: 'Eine Verbindung wird aufgebaut. Einen Moment, bitte ... Die ersten drei Minuten sind kostenfrei!',
    callReportTxt1: 'Sie haben den Anruf beendet',
    callReportTxt2: 'Anleiter:',
    callReviewLabel: 'Bist du mit der Anrufqualität zufrieden? Lass uns deine Meinung wissen.',
    callReviewBadLabel: 'Nicht zufrieden',
    callReviewGoodLabel: 'Sehr zufrieden',
    callReviewSubmitBtn: 'Senden',
    reviewTextPlaceholder: 'Was kann verbessert werden?',
    finishTxt: 'Fertig',
    finishDesc: 'Bist du mit der Anrufqualität zufrieden? Lass uns deine Meinung wissen.',
    ratingGood: 'Sehr zufrieden',
    ratingBad: 'Nicht zufrieden',
    finishNote: 'Was kann verbessert werden?',
    finishSend: 'Senden',
    finishReview: 'Rezension',
    finishBuyMore: 'Mehr Pakete kaufen',
    ratingText: 'Wie war der Anruf?',
    exit: 'Überspringen',
    noAnswer: 'Keine Antwort',
    back: 'Zurück',
    connectionDisconnect: 'Netzwerkfehler: Verbindung unterbrochen',
    reportCall: 'Fehlermeldung an Unitz senden',
    close: 'Beenden',
    endCall: 'Anruf beenden',
    timeLeft: 'Verbleibende Zeit: ',
    continueCall: 'Bist du sicher, dass du den Anruf beenden willst?',
    goBack: 'Zurück',
    endCallAction: 'Anruf beenden',
    outRoom: 'Raum verlassen',
    outRoomConfirm: 'Bist du sicher, dass du den Raum verlassen willst?',
  },
  AdvisorChatStart: {
    chatInfoTxt: 'Du hast insgesammt %{duration} Minuten zum Chatten',
    chatPolicyTx: 'Die Sitzung erfolgt über eine gesicherte Verbindung. Unitz bewahrt keine Aufnahmen der Sitzungen auf.',
    chatTxt: 'jetzt chatten',
    comingSoon: 'Coming soon',
  },
  AdvisorChat: {
    callReportTxt1: 'Der Chat wurde beendet',
    callReportTxt2: 'Anleiter:',
    callReviewLabel: 'Bist du mit dem Chat zufrieden? Lass uns deine Meinung wissen.',
    callReviewBadLabel: 'Unzufrieden',
    callReviewGoodLabel: 'Sehr zufrieden',
    callReviewSubmitBtn: 'Senden',
    chatTextPlaceholder: 'Was kann verbessert werden?',
  },
  Profile: {
    titleText: 'Account',
    yourAccount: 'Dein account',
    editProfileLabel: 'Öffentliches Profil verändern',
    changePasswordLabel: 'Passwort ändern',
    nameLabel: 'vollständiger Name',
    emailLabel: 'Email',
    phoneLabel: 'Telefonnummer',
    oldPasswordLabel: 'Altes Passwort',
    inputPassword: 'Bitte gib dein jetziges Passwort ein',
    newPasswordLabel: 'Geib dein neues Passwort ein',
    confirmPasswordLabel: 'Neues Passwort wiederholen',
    message: 'Nachrichten',
    balance: 'Deine Credits',
    paymentHistory: 'Zahlungsprotokoll',
    paymentSetup: 'Zahlungseinstellungen',
    subPaymentSetup: 'Liste der angegebenen Bankkarten',
    changePassword: 'Passwort ändern',
    helpCenter: 'Hilfe',
    subHelpCenter: 'Hier bekommst du Hilfe bei Fragen und Problemen.',
    review: 'Bewerte Unitz im Appstore oder im Google Playstore!',
    overview: 'Über Unitz',
    service: 'Service',
    price: 'Preise',
    time: 'Minuten',
    totalMoney: 'Gesamtbetrag',
    connected: 'verbunden',
    updatePass: 'Passwort erneuern',
    updatePassSuccess: 'Passwort erfolgreich erneuert',
    updatePassFail: 'Passworterneuerung fehlgeschlagen',
    currentPassword: 'Bitte, vergewissere dich, dass du das richtige Passwort eingegeben hast.',
    matchPassword: 'Das alte Passwort kann nicht erneut benutzt werden.',
    confirmPass: 'Passwörter stimmen nicht überein',
    forgotPassword: 'Passwort vergessen?',
    resetPassword: 'Passwort zurücksetzen',
    getNewPassWord: 'Eine Nachricht mit einer Anleitung, wie dein Passwrot ändern kannst, wurde an deine Emailadresse gesendet.',
    sendEmail: 'Email gesendet',
    sendEmailSuccess: 'Eine Email wurde versendet. Bitte schau in deinem Posteingang nach.',
    enterEmail: 'Bitte gib deine Emailadresse, mit der du dich registriert hast, ein. Dir wird eine Anleitung, wie du dein Passwort zurücksetzen kannst zugeschickt.',
    send: 'Senden',
    transactionHistory: 'Meine Transaktionen',
    subTransactionHistory: 'Erworbene Anrufe/Pakete',
    notification: 'Benachrichtigungen',
    subNotification: 'Transaktionsinformationen, Rückerstattungen, Verpasste Anrufe',
    myWallet: 'Meine Portmonee',
    subMyWallet: 'Portmonee-Details, kürtzliche Transaktionen, Einzahlungen',
    gift: 'Geschenke',
    subGift: 'Besondere Geschenke von Unitz',
    schedule: 'Mein Zeitplan',
    subSchedule: 'Liste geplanter Anrufe',
    course: 'Meine Kurse',
    subCourse: 'Kursliste',
    request: 'Requests',
    activities: 'Activities',
    subRequest: 'List of requests you have sent to the system',
  },
  ProfileAdvisor: {
    socialLink: 'Socialer Link',
    social_link: 'Soziales Netzwerk',
    socialLinkPlaceholder: 'Gib hier den Link zu dem von dir genutzten Sozialen Netzwerk ein. z.B.: https://www.linkedin.com/in/...',
    notification: 'Benachrichtigung',
    review: 'Rezensionen & Bewertungen',
    globalMessage: 'Community-Nachricht',
    seeSample: 'Beispielvideo ansehen',
    learnMore: 'Mehr darüber',
    watchVideo: 'Video ansehen',
    message: 'Nachrichten',
    message_vi: 'Nachrichten (vi)',
    message_en: 'Nachrichten (en)',
    setting: 'Accountinformationen ändern',
    bonusMoney: 'Auszeichnungen',
    course: 'Kurse',
    helpAdvisor: 'Hilfe',
    videoIntroduce: 'Einführungsvideo',
    infoProfile: 'Accountinfo',
    avatar: 'Profilbild',
    fullName: 'vollständiger Name',
    phoneNumber: 'Telefonnummer',
    email: 'Email',
    identify: 'Ausweisnummer',
    identifyReleaseDate: 'Ausstellungsdatum',
    identifyReleasePlace: 'ausstellende Behörde',
    permanentAddress: 'Meldeadresse',
    specialityDesc: 'Beschreibung',
    passport: 'Reisepass',
    taxNumber: 'Steuernummer',
    scoringInt: 'Scores',
    editProfile: 'Accountinformationen ändern',
    edit: 'Bearbeiten',
    save: 'Speichern',
    personalPage: 'Deine Profilseite',
    intro_text: 'Einleitung',
    update: 'Update',
    example: 'Beispiel: Ich habe über 10 Jahre Erfahrung im Kundenbeziehungs-Management.',
    success: 'Informationen erfolgreich geändert',
    fail: 'Update fehlgeschlagen',
    detail_text: 'Detailierte Beschreibung',
    category: 'Kategorien',
    degree: 'Zeugnisse & Zertifikate',
    enterEmail: 'Bitte, gib deine Emailadresse, mit der du dich registirert hast, ein. Eine Anleitung, wie du dein Passwort zurücksetzen kannst, wird an diese Adresse gesendet.',
    enterNewPassword: 'Gib dein neues Passwort ein',
    newPassword: 'Neues Passwort',
    newPasswordConfirm: 'Neues Passwort bestätigen',
    send: 'Nachricht abschicken',
    invalidEmail: 'Ungültige Emailadresse',
    required: 'erforderlich',
    passwordMustMatch: 'Die Passwörter passen nicht zusammen.',
    yinYang: 'Yin Yang',
    heart: 'Liebe - Heirat',
    coin: 'Geld - Karriere',
    family: 'Familie und Kinder',
    casino: 'Eigentum - Immobilien',
    photo: 'Fotos',
    video: 'Videos',
    usingSteps: 'Vier einfache Schritte, um zu beginnen.',
    commentAndReview: 'Rezensionen und Bewertungen',
    consultingSteps: 'Vier einfache Schritte, um die passende Beratung zu bekommen.',
    consultingStyle: 'Methoden',
    workingTime: 'Arbeitsstunden',
    callPackages: 'Anruf Pakete',
    contact: 'Anleiter kontaktieren',
    reviews: 'Rezensionen',
    more: 'Mehr anzeigen',
    collapse: 'Weniger anzeigen',
    callText: 'Sprachanruf',
    callVideoText: 'Videoanruf',
    smsText: 'Chat',
    nextPage: 'Nächste Seite',
    prevPage: 'Vorherige Seite',
    page: 'Seite',
    styleSlider: {
      title: 'Anleitungsstil',
      friendly: 'Freundlich',
      polite: 'Höflich',
    },
    speakSlider: {
      title: 'Sprechgeschwindigkeit',
      slow: 'Langsam',
      fast: 'Schnell',
    },
    teachSlider: {
      title: 'Feedback-Stil',
      flexible: 'Bedacht',
      direct: 'Direkt',
    },
    tabs: {
      profileTabLabel: 'Über mich',
      courseTabLabel: 'Kurse',
      reviewTabLabel: 'Rezensionen',
    },
    editAvatar: 'Profilbild ändern',
    editPhoto: 'Bilder hinzufügen',
    choiceImage: 'Bild auswählen',
    editAvatarSuccess: 'Profilbild geändert',
    editAvatarFail: 'Änderung fehlgeschlagen',
    editPhotoSuccess: 'Upload erfolgreich',
    editPhotoFail: 'Upload fehlgeschlagen',
    acceptPermission: 'Bitte, lass UNITZ auf deine Kamera zugreifen, um die App weiter benutzen zu können.',
    goProfile: 'Öffentliches Profil anschauen',
    education: 'Bildung',
    certificates: 'Zeugnisse & Zertifikate',
    experience: 'Berufserfahrung',
    schedule: 'Mein Zeitplan',
    contractNote: 'Ein neuer Vertrag mit aktualisierten Informationen wurde für dich erstellt. Bitte, lies dir den Vertrag durch und unterzeichne ihn, um unsere Dienste weiterhin zu nutzen.',
    contract: 'Vertrag Unterzeichnen',
    contractMessage: 'Erfolg',
    contractDescription: 'Du hast deine Vertragsdaten erfolgreich eingereicht.',
    takeSurvey: 'Bitte nimm dir einen Moment Zeit, um an der Umfrage teilzunehmen',
    activities: 'Activities',
  },
  FirebaseMessage: {
    'auth/user-not-found': {
      title: 'Ungültige Emailadresse',
      message: 'Es existiert leider kein Account mit dieser Emailadresse.',
    },
    'auth/user-disabled': {
      title: 'Ungültiger Account',
      message: 'Der Account mit dieser Emailadresse wurde leider deaktiviert.',
    },
    'auth/invalid-email': {
      title: 'Ungültige Emailadresse',
      message: 'Die Emailadresse ist ungültig.',
    },
    'auth/invalid-action-code': {
      title: 'Fehler: Ungültiger Pfad',
      message: 'Ungültiger Pfad. Bitte, kontaktiere den Unitz-Support, um das Problem zu beheben.',
    },
    'auth/email-already-in-use': {
      title: 'Ungültige Emailadresse',
      message: 'Die Emailadresse ist leider schon vergeben.',
    },
    'auth/wrong-password': {
      title: 'Ungültiges Passwort',
      message: 'Das Passwort ist ungültig',
    },
  },
  Search: {
    searchResult: 'Suchergebnis für ',
    searchResultTotalHead: 'Über ',
    searchResultTotal: '%{total} Ergebnisse ',
    searchResultTotalTail: 'für den Suchbegriff.',
    suggestedAdvisors: 'Außerdem könnte dich interessieren:',
    screenTitleTxt: 'Suchen',
    searchAdvanced: 'Erweiterte Suche',
    delete: 'Löschen',
    confirm: 'Bestätigen',
    searchCategory: 'Suche nach Kategorien',
    searchSkill: 'Skills',
    category: 'Kategorien',
    skill: 'Wähle einen Skill',
    review: 'Bewertung',
    star_5: '%{star} Stern',
    star_4: 'From %{star} Sterne',
    result: '%{result} Übereinstimmungen',
    allCategory: 'Alle Kategorien',
  },
  tts: {
    videoCall: {
      timeoutAlert: 'Der Anruf wird nach %{duration} Minuten beendet.',
    },
  },
  currency: {
    thousand: 'Tsd.',
    million: 'Mio.',
    billion: 'Mrd.',
  },
  time: {
    day: 'Tag',
    hour: 'Stunde',
    minute: 'Minute',
    second: 'Sekunde',
    month: 'Monat',
    year: 'Jahr',
    currentMonth: 'Aktueller Monat',
    minutes: 'Minuten',
  },
  AdvisorProfileMenu: {
    Dashboard: 'Dashboard',
    Home: 'Home',
    Overview: 'Übersicht',
    Message: 'Nachrichten',
    MyCourse: 'Meine Kurse',
    TeachingSchedule: 'Lehrplan',
    Notification: 'Benachrichtigungen',
    CourseManagement: 'Kursverwaltung',
    ProfileManagement: 'Profilverwaltung',
    SettingWorkingTime: 'Arbeitsstunden',
    MyIncome: 'Mein Einkommen',
    CallHistory: 'Anrufverlauf',
    Account: 'Account',
    Setting: 'Einstellungen',
    Request: 'Requests',
  },
  AdvisorProfileGeneral: {
    Courses: 'Kurse',
    CourseLower: 'Kurs',
    CoursesLower: 'Kurse',
    NoCourseAddMore: 'Du hast keine laufenden Kurse. Bitte füge hier einen Kurs hinzu!',
    NotPublished: 'Nicht veröffentlicht',
    GoingOn: 'Laufend',
    ComingSoon: 'Anstehend',
    AddCourse: 'Kurs hinzufügen',
    Detail: 'Detail',
    TodaySchedule: 'Heutiger Kursplan',
    NoStudentYet: 'momentan keine Teilnehmer',
    Finished: 'Beendet',
    JoinNowClass: 'Jetzt beitreten',
    OnlineAdvise: 'Anrufanfrage-Einstellungen',
    NoAnyConsultationSession: 'momentan keine Anrufanfragen',
  },
  AdvisorProfile: {
    Overview: 'Übersicht',
    Skill: 'Skills',
    Rating: 'Bewertung',
    Title: 'Anleiter Profil',
    aboutMe: 'Über mich',
    photos: 'Fotos',
    category: 'Kategorien',
    advisorStyle: 'Methoden',
    howToSpeak: {
      name: 'Anleitungsstil',
      min: 'Freundlich',
      max: 'Höflich',
    },
    speakingSpeed: {
      name: 'Sprechgeschwindigkeit',
      min: 'langsam',
      max: 'schnell',
    },
    howToConveyTheResult: {
      name: 'Feedback Stil',
      min: 'bedacht',
      max: 'direkt',
    },
    timeWork: 'Arbeitsstunden',
    more: 'Mehr anzeigen',
    collapse: 'Weniger anzeigen',
    noName: 'Anonym',
    callVoice: 'Sprachanruf',
    callVideo: 'Videoanruf',
    callBusy: 'beschäftigt',
    addImage: 'Bild hinzufügen',
    SetSchedule: 'Termin vereinbaren',
    Timezone: 'Timezone',
    restartMessageTimezone: 'The app will automatically restart to update Timezone.',
    status: {
      available: 'Available Now',
      takingAppointment: 'Taking Appointments',
      notAvailable: 'Not Available',
    },
  },
  Biz: {
    Form: {
      organization_name: 'Schule',
      country_label: 'Land',
      country_placeholder: 'Bitte auswählen',
      register: 'Nachricht abschicken',
      success_notification: '',
      success_description: 'Die Nachricht ist erfolgreich bei uns eingegangen. Wir werden uns in Kürze bei ihnen melden',
      title: 'Beruf',
      email: 'E-Mail-Adresse',
      last_name: 'Nachnahme',
      first_name: 'Vornahme',
      phone_label: 'Telefonnummer',
      phone_placeholder: '+49 150 567 8910',
      message: 'Ihre Nachricht',
      message_placeholder: '',
      check_text: '',
      errorNotification: 'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal.',
    },
    Blog: {
      all_articles: 'All Articles',
      author: 'Author',
      date: 'Date',
      read_article: 'Read article',
      other_articles: 'Other Articles',
      no_blog: 'The page you requested does not exist',
      page: 'Page',
      of: 'of',
      next: 'Next',
      prev: 'Prev',
      goTo: 'Go to',
    },
    read_more: 'Read more',
  },
  Course: {
    aboutCourse: 'Über den Kurs',
    about: 'Über den Kurs',
    createCourseTitle: 'Neuen Kurs erstellen',
    courseLabel: 'Kurse',
    detail_course: 'Kursdetails',
    schedule: 'Zeitplan',
    course: '%{total} Kurse',
    classNotes: 'Kursnotizen',
    teaching_schedule: 'Course session',
    coming_room: 'Beginnt am',
    course_of_lecture: 'Mehr Kurse',
    files: 'Ordner',
    supports: 'Supports',
    reviews: 'Rezensionen',
    per_session: 'Unterrichteinheit',
    per_course: 'Kurs',
    openingDay: 'Beginn am %{startAt}',
    enrollNow: 'Jetzt einschreiben',
    edit_course: 'Kurs bearbeiten',
    viewCourse: 'Kurse anschauen',
    myCourse: 'Meine Kurse',
    subMyCourse: 'Kurse, in die du eingeschrieben bist',
    paidAlready: 'Bereits bezahlt',
    general: 'Allgemein',
    overview: 'Übersicht',
    activity: 'Aktivitäten',
    shareTitle: 'Kurse teilen',
    share: 'Teilen',
    allowTrialAccess: 'Kostenlose Probestunde erlauben',
    courseBenefits: 'Vorteile mit Unitz',
    benefits: {
      benefit1: 'Tritt live dem Online-Unterricht mit dem Anleiter deiner Wahl bei.',
      benefit2: 'Lerne in 1 zu 1 oder Gruppensitzungen so, wie es zu dir passt.',
      benefit3: 'Schreibe dich für kostenlose Probestunden ein.',
      benefit4: 'Lerne am Laptop oder am Tablet über den Browser oder per App',
      benefit5: 'Es ist nicht notwendig, extra Software zu installieren.',
      benefit6: 'Von den Anleitern und Lehrern selbst festgelegte Preise / Transparente Preiszusammensetzung',
      benefit7: 'permanente Optimierung der Performance',
      benefit8: 'Spare Zeit, indem du bequem von zuhause beitrittst, ohne den Weg zum Bildungsinstitut.',
      benefit9: 'Arbeite auch nach Ende einer Online-Sitzung mit den Notizen der Sitzung und greife auf hochgeladene Dokumente zu, um Übungen zu wiederholen und dein Wissen zu festigen.',
      benefit10: 'Flexible Zeiteinteileung - egal wo, egal wann.',
    },
    demo: {
      messageInfo: 'Nimm zuerst eine Probestunde, bevor du dich in einen Kurs einschreibst. Wähle unten einen Zeitpunkt für die Probestunden und schicke diese Einschreibung ab. Der Anleiter/Lehrer wird deinen vorgeschlagenen Termin prüfen und ihn, wenn es passt, bestätigen.',
      buttonTitle: 'Für eine Probestunde registrieren',
      buttonTitleShort: 'Probestunde',
      title: 'Probestunde',
      messageHint: '(Du kannst dich für eine kostenlose Probestunde einschreiben)',
      messageNoTrial: '(Für diesen Kurs gibt es leider keinen Platz für eine kostenlose Probestunde)',
      messageRequested: '(Du hast dich für eine kostenlose Probestunde eingeschrieben)',
      modal: {
        title: 'Für Probestunde einschreiben',
        okButton: 'Einschreiben',
        cancelButton: 'Schließen',
        doneButton: 'Fertig',
        sessionDurationLabel: 'Dauer',
        startDateLabel: 'Datum wählen',
        startTimeLabel: 'Sitzung',
        startAtLabel: 'Zeit',
        results: {
          success: {
            title: 'Erfolgreich für eine Probestund eingeschrieben!',
            subTitle: 'Bitte, warte auf die Bestätigung des Anleiters und bereitedich vor, der Sitzung beizutreten!',
          },
          error: {
            title: 'Ein Fehler ist aufgetreten!',
            subTitle: 'Bitte, kontrolliere und verändere die folgenden Angaben, bevor du dich erneut einschreibst.',
          },
        },
      },
      modalConfirm: {
        title: 'Antwort auf Probestundenantrag',
        messageInfo: 'Teilnehmer können nur einmal eine Probestunde beantragen. Teilnehmer können nur eine Probestunde nehmen, wenn der Anleiter ihren Antrag akzeptiert.',
        okButton: 'Akzeptieren',
        cancelButton: 'Schließen',
        doneButton: 'Fertig',
        confirmButton: 'Akzeptieren',
        rejectButton: 'Ablehnen',
        sessionDurationLabel: 'Dauer',
        startDateLabel: 'Datum auswählen',
        startTimeLabel: 'Zeit auswählen',
        studentLabel: 'Teilnehmer',
        notesLabel: 'Notizen',
        results: {
          success: {
            title: 'Erfolgreich für eine Probestunde eingeschrieben!',
            subTitle: 'Bitte, warte auf die Bestätigung des Anleiters und bereitedich vor, der Sitzung beizutreten!',
          },
          error: {
            title: 'Ein Fehler ist aufgetreten!',
            subTitle: 'Bitte, kontrolliere und verändere die folgenden Angaben, bevor du dich erneut einschreibst.',
          },
        },
      },
    },
    shareContent: "Let's study with me!",
    course_count: {
      zero: '0 Kurse',
      one: '1 Kurs',
      other: '%{count} Kurse',
    },
    course_is_teaching: {
      zero: 'Momentan werden 0 Kurse gegeben',
      one: 'Momentan wird 1 Kurs gegeben',
      other: 'Momentan werden %{count} Kurse gegeben',
    },
    contact: 'In Kurs einschreiben',
    emptyCourse: 'Kursliste ist leer',
    seeAll: 'Alle ansehen',
    rooms: 'Räume',
    Info: {
      helperMessage: 'Tuition fee to be collected from students, please add on top of your desired rate an additional platform fee of 20% to your pricing. An example, if you charge 200k per hour lesson, then set the price per hour for this course at 240.000 vnd. Hence you will earn the full amount of 200k for your one hour of teaching. Note that, this is the fee that each of your students will pay. If there are 3 students in your class then you will earn 3x200k = 600k per one hour.',
      new_course: 'Neue Kurse',
      step_title: 'Kursinformation',
      scoring_int_input_label: 'Wertung',
      scoring_int_input_placeholder: 'Beispiel: 100',
      name_input_label: 'Titel',
      name_input_placeholder: 'Kurstitel hinzufügen',
      description_input_label: 'Beschreibung',
      description_input_defaultValue: '<p>Course Einführung:&nbsp;</p><p>Zielgruppe:</p><p>Kursinhalte im Detail:</p><p>Was lernen die Teilnehmer:</p><p>Lehrbücher:</p><p>Kursplan:</p><p>Verpflichtung:</p>',
      description_input_placeholder: 'Gebe eine Kurzbeschreibung deines Kurses einBriefly describe your course',
      photo_url_input_label: 'Titelbild des Kurses',
      video_url_input_label: 'Kursvideo',
      video_url_input_placeholder: 'Kursvideo',
      price_amount_per_session: 'Preis/Sitzung',
      price_amount_per_course: 'Preis/Kurs',
      no_photo: 'Kein Titelbild',
      no_video: 'Kein Video',
      session_duration_input_label: 'Dauer/Sitzung',
      session_duration_input_placeholder: 'Dauer auswählen',
      start_at_input_label: 'Startdatum',
      pricing_type_input_label: 'Kursbeitrag pro',
      object_teaching_input_label: 'Zielgruppe',
      age_input_label: 'Alter ',
      children_age_input_label: '(Bitte mindestens ein Alter zwischen 3 und 18 Jahren eingeben)',
      to_age_label: 'bis',
      years_old_label: 'Jahre alt',
      minimum_sessions_per_time: '%{number} Saisons',
      frequency_of_tuition: {
        zero: 'Kursbeitrag für 0 Sitzungen mit einem Mal zahlen ',
        one: 'Kursbeitrag für 1 Sitzung mit einem Mal zahlen',
        other: 'Kursbeitrag für %{count} Sitzungen mit einem Mal zahlen',
      },
      tuition_by_course: 'Kursbeitrag mit einem Mal komplett zahlen',
      minute_per_session: '%{minute} Minuten / Sitzung',
      benefit_input_label: 'Was du lernen wirst',
      benefit_input_hint: '(Gib zwei Vorteile an, von denen die Teilnehmer profitieren, wenn sie deinen Kurs besuchen)',
      benefit_input_placeholder: 'Bsp.: authentischer Sprechstil - Dialekte, Englisch für den täglichen Gebrauch, Vorbereitung auf Sprachzertifikatsprüfung',
      category_input_label: 'Kategorie wählen',
      category_input_placeholder: 'Wähle die Kategorie, mit der der Kurs assoziiert werden soll.',
      minute: {
        zero: '0 Minuten',
        one: '1 Minute',
        other: '%{count} Minuten',
      },
      student: {
        zero: '0 Teilnehmer',
        one: '1 Teilnehmer',
        other: '%{count} Teilnehmer',
      },
      session: {
        zero: '0 Sitzungen',
        one: '1 Sitzung',
        other: '%{count} Sitzungen',
      },
      object_teaching_for_adult: 'Erwachsene',
      object_teaching_for_children: 'Kinder',
      session_type: 'Sitzung',
      fullCourse_type: 'Ganzer Kurs',
      session_occurence_input_label: 'Anzahl der Sitzungen',
      unlimitedSession: 'Unbegrenzte Anzahl an Sitzungen',
      unlimited: 'Unbegrenzt',
      price_amount_input_label: 'Kursbeitrag',
      price_amount_input_label_by_types: {
        per_course: 'Kursbeitrag (ganzer Kurs)',
        per_session: 'Kursbeitrag (pro Sitzung)',
      },
      price_amount_input_placeholder: 'Gib deinen Preis ein',
      enroll_min_input_label: 'Mindestanzahl an Teilnehmern, damit der Kurs stattfindet',
      enroll_min_input_placeholder: 'Gib die Anzahl der Teilnehmer ein',
      enroll_max_input_label: 'Maximale Teilnehmerzahl',
      enroll_max_input_placeholder: 'Gib die Anzahl der Teilnehmer ein ein',
      attachments_input_label: 'Dateien',
      startAtTxt: 'Beginn am',
      Validation: {
        required: 'Bitte, fülle die benötigten Felder aus',
        sessionMin: 'Bitte, füge mindestens eine Kurssitzung hinzu',
        conflictSession: 'Sitzung #%{index} überschneidet sich mit einer anderen Sitzung',
        session_occurence_min: 'Nicht genügend Sitzungen %{session_count}/%{session_occurence}',
        session_occurence_max: 'Zu viele Sitzungen %{session_count}/%{session_occurence}',
        conflictsessionCourse: 'Kurszeit überschneidet sich mit der eines anderen Kurses ',
        moreThanMoney: 'Der Kursbeitrag muss höher als %{val} sein',
        lessThanMoney: 'Der Kursbeitrag muss niedriger als %{val} sein',
        limit_benefit_content_character: 'Maximal 200 Zeichen',
        min_benefit_entered: 'Bitte, gib mindestens zwi Vorteile an',
        category_input_min: 'Bitte, wähle eine Kategorie',
        courseLinkMin: "Please add at least one course's link",
        conflictSessionCourse: 'Trùng giờ với khoá học',
        max_age: 'Tuổi tối đa chưa đúng',
        min_age: 'Tuổi tối thiểu chưa đúng',
      },
      Action: {
        add_photo: 'Foto hinzufügen',
        select_files: 'Dateien wählen',
        drop_files_here: 'Dateien hier hineinziehen...',
        add_benefit: 'Vorteil ergänzen',
      },
      age_note_input_label: '(If there are many students, priority is given to the oldest student)',
      pending_message: 'You have a course waiting for approval, we have received your course on the system and will review it within 3 working days. We’ll send you notifications via our web and in-app notifications as well as by email: supplier@unitz.app, save this email address to your Contacts to always receive emails, or check spam mailbox once the course is approved.',
      category_input_min: 'Vui lòng chọn danh mục cho khóa học',
    },
    DurationSetting: {
      step_title: 'Sitzungsdauer festlegen',
      detail_study_time: 'Details zur Lehrdauer',
      time_interval: 'Sitzungsinterval',
      number: 'Nr.',
      every_week: 'wöchentlich',
      hour_frame: 'Startzeit',
      repeat: 'Wiederholung',
      repeat_pattern_input_placeholder: 'Wähle ein Datum für die Wiederholung',
      Action: {
        add_hour_frame: 'Füge eine andere Startzeit hinzu',
        add_course_link: "Add another course's link",
      },
      helperMessage: 'Die hier angegebenen Zeiten sind die tatsächlichen Start- und Endzeiten der Sitzungen dieses Kurses. Bitte stelle sicher, dass sich die Zeiten nicht mit denen deiner anderen Kurse überschneiden, da dies die Zeiten sind, die für die Teilnehmer sichbar sind. Du wirst vom System benachrichtigt, falls eine Überschneidung gibt. Wenn du alternative Zeitfenster anbieten möchtest (z.B.: Di-Do-Sa statt Mo-Mi-Fr), erstelle stattdessen bitte einen neuen Kurs.',
      start_at: 'Start at',
    },
    Payment: {
      step_title: 'Bezahlung',
      type_of_product: 'Art des Produktes',
      course: 'Kurs',
      session_per_purchase_input_label: 'Beschaffungsart',
      session_per_purchase_input_placeholder: 'Wähle die Beschaffungsart',
      should_publish_input_label: 'Der Kursbeitrag wird zurückerstattet, wenn der Teilnehmer während der Probestunde den Entschluss fasst, den Kurs nicht weiter zu besuchen.',
      should_publish_input_placeholder: 'Kurs veröffentlichen',
      full_package: 'Gesamtpreis',
      every_session: 'Preis pro Sitzung',
      session: {
        zero: '0 Sitzungen',
        one: '1 Sitzung',
        other: '%{count} Sitzungen',
      },
      paidSessionsPerTotalLabel: {
        zero: 'Bezahlt: %{count}/%{session_occurence} Sitzungen',
        one: 'Bezahlt: %{count}/%{session_occurence} Sitzung',
        other: 'Bezahlt: %{count}/%{session_occurence} Sitzungen',
      },
      paidSessionsRemainLabel: {
        zero: 'Mehr Sitzungen kaufen',
        one: '%{count} Sitzung verbleibend',
        other: '%{count} Sitzungen verbleibend',
      },
      paymentRoomListLabel: 'Bezahlung für die Sitzung',
      paidLabel: 'BEZAHLT',
      unPaidLabel: 'NOCH NICHT BEZAHLT',
    },
    Action: {
      add_course: 'Kurs hinzufügen',
      clone_course: 'Kurs duplizieren',
      save: 'Speichern',
      cancel: 'Abbrechen',
      next_step: 'Nächster Schritt',
      prev_step: 'Vorheriger Schritt',
      preview: 'Vorschau',
      copy_url: 'URL kopieren',
    },
    Status: {
      draft: {
        title: 'Entwurf',
        action: 'entwerfen',
      },
      published: {
        title: 'Veröffentlichung',
        action: 'veröffentlichen',
      },
      requested: {
        title: 'Demoanfrage',
        label: 'Demo angragen',
        action: 'Bestätigen',
      },
      confirmed: {
        title: 'Demobestätigung',
        action: 'Demo bestätigen',
      },
      rejected: {
        title: 'Demoablehnung',
        action: 'Demo abgelehnt',
      },
      completed: {
        title: 'Fertig',
        action: 'fertigstellen',
      },
      refund: {
        title: 'Rückerstattung',
        action: 'rückerstatten',
      },
      canceled: {
        title: 'Abbrechen',
        action: 'abbrechen',
      },
      closed: {
        title: 'Schließen',
        action: 'schließen',
      },
      onprogress: {
        title: 'Laufend',
        action: 'laufend',
      },
      viewAsUser: {
        title: 'Teilnehmeransicht',
        action: 'Teilnehmeransicht',
      },
      clone: {
        title: 'Kurs duplizieren',
        action: 'Kurs duplizieren',
      },
      pending_approval: {
        title: 'Pending',
        action: 'Approve',
      },
    },
    RAT: {
      buttonSeeScheduleTitle: 'See course schedule',
      messageInfo: 'You can register for a free trial lesson before purchasing this course. Select time for the trial lesson below and submit registration. Teacher will check and approve the registration.',
      messageButton: 'If there is no suitable class for you. You can send requests to the teacher for another class time.',
      buttonTitle: 'Request Another Time',
      buttonTitleShort: 'Request Another Time',
      title: 'Request Another Time',
      messageHint: '(You can register a free trial lesson)',
      messageNoTrial: '(The class does not allow trial lesson)',
      messageRequested: '(You registered a free trial lesson)',
      requestMessage: 'Request another lesson of {{courseLink}} Course of teacher {{advisorLink}} has been sent.',
      confirmMessage: 'You have just accepted a Course Request from %{user}',
      rejectMessage: 'You just declined Request another lesson from %{user}',
      pendingMessage: 'Your request is awaiting approval from the teacher',
      go_RATCourse: 'Go to course',
      DurationSetting: {
        step_title: 'Duration settings',
        detail_study_time: 'Detail study time',
        time_interval: 'Time interval',
        number: 'No.',
        every_week: 'Every week on',
        hour_frame: 'Start time',
        repeat: 'Repeat',
        repeat_pattern_input_placeholder: 'Choose repeating date',
        Action: {
          add_hour_frame: 'Add another starting time',
        },
        helperMessage: 'The starting times specified here are the actual start and end times of the lessons in this course. As students will join the time slots specified here, please ensure that the course starting time(s) do not conflict with your other courses. If there is a conflict, the system will notify you of the duplication.',
      },
      modal: {
        title: 'Register trial lesson',
        okButton: 'Register',
        cancelButton: 'Close',
        doneButton: 'Done',
        sessionDurationLabel: 'Duration',
        numberOfStudentsLabel: 'Number of students',
        startDateLabel: 'Select date',
        selectDateMainLabel: 'Select date and time',
        selectDateLabel: 'Select date',
        startTimeLabel: 'Session',
        startAtLabel: 'Time',
        notesLabel: 'Notes',
        notesPlaceholder: 'Write your notes here',
        requested_note1: 'You received 01 request from user',
        requested_note2: 'Please respond as soon as possible!',
        requested_note3: 'If you agree with the above requirements, the system will automatically create a course with similar content',
        results: {
          success: {
            title: 'Successfully registered trial lesson!',
            subTitle: 'Please wait for confirmation from teacher and prepare to join to lesson.',
          },
          error: {
            title: 'Submission failed!',
            subTitle: 'Please check and modify the following information before resubmitting.',
          },
        },
      },
      registration: {
        label1: 'Your request has been sent successfully',
        label2: 'You can track requests in the Requests section of your account',
        info: 'Information required',
        seeRequire: 'View request now',
      },
      modalConfirm: {
        title: 'Response to trial lesson request',
        messageInfo: 'Students can submit a request for a trial lesson only once. Students can only enter the trial class when the teacher accepts the request for a trial lesson.',
        okButton: 'Accept',
        cancelButton: 'Close',
        doneButton: 'Done',
        confirmButton: 'Accept',
        rejectButton: 'Decline',
        sessionDurationLabel: 'Duration',
        startDateLabel: 'Select date',
        startTimeLabel: 'Select time',
        studentLabel: 'Student',
        notesLabel: 'Notes',
        results: {
          success: {
            title: 'Successfully registered trial lesson!',
            subTitle: 'Please wait for confirmation from teacher and prepare to join to lesson.',
          },
          error: {
            title: 'Submission failed!',
            subTitle: 'Please check and modify the following information before resubmitting.',
          },
        },
      },
    },
    RCM: {
      confirmed: 'Confirmed the course request',
      rejected: 'Rejected the course request',
      responsor: 'Responsor',
      buttonResponseTitle: 'Response',
      buttonRejectTitle: 'Reject',
      buttonTitle: 'Request course matching',
      buttonTitleShort: 'Request course matching',
      requestMessage: 'Request your another course has been sent.',
      confirmMessage: 'You have just accepted a course request from %{user}',
      rejectMessage: 'You just declined a course request from %{user}',
      rejectedMessage: 'You have been declined a course request from admin',
      pendingMessage: 'Your request is awaiting response from the teacher',
      noteLabel: 'Note',
      linkCourse: 'Courses matching',
      messageInfo: 'We will find a course matching or recommend a teacher to create a course if approved. So please let us know the details of your request.',
      Status: {
        requested: 'requested',
        rejected: 'rejected',
        confirmed: 'confirmed',
      },
      modal: {
        title: 'Request another course',
        topicLabel: 'Fill in the topic you want to study',
        literacyLabel: 'Level',
        teacherLabel: 'Instructor (If you have researched about the instructor before)',
        people: 'people',
        courseLink: "Course's link",
        responseButton: 'Submit',
        rejectButton: 'Reject',
        literacies: {
          beginner: 'Beginner',
          elementary: 'Elementary',
          intermediate: 'Intermediate',
          advanced: 'Advanced',
        },
        results: {
          success: {
            title: 'Successfully requested course!',
            subTitle: 'Please wait for confirmation from Unitz and prepare to join to lesson.',
          },
          error: {
            title: 'Submission failed!',
            subTitle: 'Please check and modify the following information before resubmitting.',
          },
        },
      },
      modalConfirm: {
        title: 'Response to trial lesson request',
      },
    },
  },
  CourseRoom: {
    purchase: {
      transaction_per_purchase_tooltip: 'Transaktionen/Anschaffungen',
    },
    validation: {
      invalid_room_attendee: 'Du bist nicht in diesen Kurs einbeschrieben.',
      invalid_room_purchase: 'Du kannst diesen Raum nicht betreten, da dafür nicht bezahlt wurde.',
      invalid_room_time: 'Der raum ist nicht geöffnet.',
      invalid_room_completed: 'Die Sitzung ist noch nicht abgeschlossen.',
      invalid_room_host: 'Du kannst diesem Raum nicht als Leiter beitreten.',
      invalid_room: 'Dieser Raum existiert nicht',
    },
    status: {
      ready_to_join: 'Bereit beizutreten',
      not_scheduled: 'Die Sitzung ist momentan nicht geöffnet',
    },
    message: {
      invalid_room: 'Überprüfe Die Sitzungsinformationen.',
      check_camera_micro: 'Überprüfe deine Kamera-, Mikrofon und Lautsprechereinstellungen!',
      not_scheduled: 'Eine Videoübertragung außerhalb der Sitzungen ist nicht möglich. Du kannst trotzdem mit den Notizen unnd Dokumenten arbeiten!',
    },
    label: {
      camera: 'Kamera',
      micro: 'Mikrofon',
      speaker: 'Lautsprecher',
      view_class: 'Sitzung anschauen',
    },
    placeholder: {
      camera: 'Kamera auswählen',
      micro: 'Mikropfon auswählen',
      speaker: 'Lautsprecher auswählen',
    },
    menus: {
      message: {
        title: 'Nachricht schicken',
      },
      reschedule: {
        title: 'Sitzung verschieben',
      },
      refund: {
        title: 'Sitzungskosten zurückerstatten',
      },
      dismiss: {
        title: 'Sitzung beenden',
      },
      unfollow: {
        title: 'Nicht mehr folgen',
      },
      cancel: {
        title: 'Raum abbrechen',
      },
    },
    modals: {
      message: {
        title: 'Nachricht senden',
        infoMessage: 'Nachricht senden',
        okText: 'Senden',
        cancelText: 'Schließen',
      },
      reschedule: {
        title: 'Verschieben',
        infoMessage: 'Verschieben',
        okText: 'Verschieben',
        cancelText: 'Schließen',
        currentTimeFrameLabel: 'Aktuelle Zeit',
        newTimeFrameLabel: 'Neue Zeit',
        updateSameRoomsLabel: 'Neue Zeit für gleiche Räume anwenden.',
      },
      refund: {
        title: 'Rückerstattung',
        infoMessage: 'Rückerstattung',
        confirmMessage: 'Bitte, gib den Namen des Kurses in das Feld unten ein, um mit der Rückerstattung fortzufahren.',
        okText: 'Rückerstattung',
        cancelText: 'Schließen',
      },
      dismiss: {
        title: 'Auflösen',
        infoMessage: 'Auflösen',
        confirmMessage: 'Bitte, gib den Namen des Kurses in das Feld unten ein, um mit der Auflösung des Kurses fortzufahren.',
        okText: 'Auflösen',
        cancelText: 'Schließen',
      },
      unfollow: {
        title: 'Nicht mehr folgen',
        infoMessage: 'Nicht mehr folgen',
        confirmMessage: 'Bitte, gib den Namen des Kurses in das Feld unten ein, um diesem Kurs nicht mehr zu folgen.',
        okText: 'Nicht mehr folgen',
        cancelText: 'Schließen',
      },
      cancel: {
        title: 'Raum beenden',
        infoMessage: 'Willst du diesen Raum wirklich beenden? Du wirst keine Bezahlung für die noch nicht gegebenen Sitzungen erhalten und Unitz wird den Teilnehmern eine Rückerstattung zukommen lassen.',
        confirmMessage: 'Bitte, gib den Namen des Kurses in das Geld unten ein, um mit dem Antrag um Beendigung des Kurses fortzufahren.',
        okText: 'Ok',
        cancelText: 'Schließen',
      },
    },
    info: {
      Status: 'Status',
      NumberOfStudent: 'Number of student',
      Attendee: {
        zero: 'No one has joined yet',
        one: '1 participant',
        other: '%{count} participants',
      },
    },
  },
  CourseReview: {
    title: 'Für heute ist die Sitzung beendet!',
    description: 'Erzähl mehr!',
    question1: 'Wie fandest du die Sitzung?',
    question2: 'Möchtest du deinem Anleiter eine Bewertung dalassen?',
    question3: 'Möchtest du dem Anleiter ein Kommentar schreiben? Zögere nicht, uns deine Meinung mitzuteilen!',
    place_holder: 'Möchtest du ein Kommentar über den Kurs schreiben? Es wird anderen Teilnehmern helfen, den richtigen Kurs für sich zu finden.',
    completeTitle: 'Danke für deine Bewertung!',
    completeDescription: 'Dein Feedback hilft uns, UNITZ weiter zu verbessern!',
    Action: {
      goHome: 'Zurück zur Startseite',
      confirm: 'Bestätigen',
      rateNow: 'Jetzt bewerten',
      ok: 'OK',
    },
  },
  HomeSearchCourse: {
    per_session: 'Sizung',
    per_course: 'Kurs',
    Label: {
      field: 'Fachgebiet',
      categories: 'Kategorien',
      session_duration: 'Sitzungsdauer',
      tuition: 'Kursbeitrag',
    },
    Placeholder: {
      field: 'Wähle ein Fachgebiet, das dich interessiert',
      categories: 'Wähle eine Kategorie, aus der du lernen möchtest',
      session_duration: 'Gib die Sitzungsdauer an',
      tuition: 'Kursbeitrag pro',
    },
    Action: {
      search: 'Kurs suchen',
    },
    course_more: 'See more courses on the same topic here.',
  },
  InstructorFilter: {
    title: 'Anleiterliste',
    filterTools: 'Filter',
    show: 'Anzeigen',
    quantity: 'Anzahl',
    emptyFilterTxt1: 'Es gibt im Moment keinen Anleiter in der Kategorie %{category}.',
    emptyFilterTxt2: 'Wir benachrichtigen dich, sobald es verfügbare Anleiter gibt.',
    emptyFilterTxt3: 'Bis dahin kannst du nach anderen Anleitern suchen!',
    apply: 'Anwenden',
    ratings: 'über %{count}',
    menu: {
      category: 'Kategorie',
      priceRange: 'Preisspanne',
      duration: 'Dauer',
      ratings: 'Bewertungen',
    },
    sortBy: {
      placeHolder: 'Sortieren nach',
      newest: 'Neueste',
      outstanding: 'Beliebteste',
      highRating: 'Bewertung: Höchste zu Niedrigste',
    },
    result: {
      zero: '0 Ergebnisse',
      one: '1 Ergebnis',
      other: '%{count} Ergebnisse',
    },
  },
  CourseFilter: {
    title: 'Kursliste',
    filterTools: 'Filter',
    show: 'Zeigen',
    sessionUnit: 'Sitzung',
    videoDemo: 'Demovideo',
    quantity: 'Anzahl',
    emptyFilterTxt1: 'In dieser Kategorie sind momentan noch keine Kurse verfügbar. Es werden bald neue Kurse hinzugefügt!',
    emptyFilterTxt2: 'Du wirst benachrichtig, wenn Kurse hinzugefügt werden.We will notify you when that happens.',
    emptyFilterTxt3: 'Sucher in der Zwischenzeit gerne in anderen Kategorien nach interessanten Kursen!',
    apply: 'Anwenden',
    ratings: 'über %{count}',
    menu: {
      category: 'Kategorie',
      priceRange: 'Preisspanne',
      duration: 'Dauer',
      ratings: 'Bewertung',
    },
    sortBy: {
      placeHolder: 'Sortieren nach',
      newest: 'Neuste',
      outstanding: 'Beliebteste',
      highRating: 'Bewertung: Höchste zu Niedrigste',
      viewing: 'Viewing',
    },
    result: {
      zero: '0 Ergebnisse',
      one: '1 Ergebnis',
      other: '%{count} Ergebnisse',
    },
    recommendedCourses: 'Empfohlene Kurse',
  },
  Teacher: {
    lecturer: 'Anleiter',
    more: 'Profil',
    teachingExpertise: 'praktische Lehrerfahrung',
    myCourse: 'Meine Kurse',
    teachingStyle: 'Methoden',
    howToSpeak: {
      name: 'Anleitungsstil',
      min: 'Freundlich',
      max: 'Höflich',
    },
    speakingSpeed: {
      name: 'Sprechgeschwindigkeit',
      min: 'Langsam',
      max: 'Schnell',
    },
    howToConveyTheResult: {
      name: 'Feedback-Stil',
      min: 'Bedacht',
      max: 'Direkt',
    },
    titleHeader: 'Anleiterprofil',
    title: 'Anleiter',
    featured: 'Empfohlene Anleiter',
  },
  Payment: {
    StepOneTitle: '1. Wähle dein Anrufpaket',
    StepTwoTitle: '2. Pay using Unitz wallet',
    StepTwoTitleVN: '2. Wähle eine andere Zahlungsmethode',
    StepThreeTitleVN: '3. Anbieten',
    StepThreeTitle: '3. Wähle eine andere Zahlungsmethode',
    StepFourTitle: '4. Anbieten',
    Bill: {
      package: 'Paket',
      callVideo: 'Videoanruf',
      tempCost: 'Preis',
      discountCost: 'Rabatt',
      totalCost: 'Gesamtpreis',
      unitzCoin: 'Unitz wallet',
      promotion: 'Werbeaktion',
      priceUnit: 'Preis pro Minute',
      pay: 'Jetzt bezahlen',
    },
    Wallet: {
      equivalent: 'Gegenwert',
    },
    Profile: {
      reviews: 'Bewertungen',
      advisor: 'Anleiter',
    },
    Method: {
      myCard: 'Meine Karte',
      domesticDebitCard: 'EC Karte / Online Banking',
      visaMasterCard: 'Visa / Master Card',
      newCard: 'Karte hinzufügen',
      selectCard: 'Karte auswählen',
      selectBank: 'Bank auswählen',
    },
    Discount: {
      selectDiscountPlaceHolder: 'Rabattcode eingeben',
      optionLabelDiscountPercentage: 'Rabatt %{percentage}%',
      optionLabelDiscountAmount: 'Rabatt %{amount}',
      optionLabelDiscountPercentageMaxAmount: 'Rabatt %{percentage}% bis %{amount}',
    },
    Error: {
      service: 'Wähle einen Dienst',
      credit: 'Do you want to pay with Unitz wallet?',
      bank: 'Please choose your bank card.',
      token: 'Please choose your card.',
    },
    Action: {
      payNow: 'Pay now',
      joinClass: 'Go to class',
    },
  },
  Wallet: {
    walletTxt: 'Mein Portmonee',
    walletSurplus: 'Portmonee Inhalt:',
    Action: {
      addVoucher: 'Gutschein aktivieren',
      recharge: 'Credits hinzufügen',
      transfer: 'Transfer',
    },
    Table: {
      transactionDate: 'Transaktionsdatum',
      detailTransaction: 'Details',
      status: 'Status',
      Amount: 'Höhe',
    },
  },
  Bank: {
    bankListTxt: 'Bank Liste',
    internationalPayment: 'Debit or Kredit Karde',
    paypalPayment: 'PayPal',
    domesticPayment: 'Bankkonto',
    emptyCardList: 'Ich habe keine Karte',
    default: 'Voreinstellungen',
    addInternationalCard: 'Kredit Karte / Debit Karte hinzufügen',
    addPaypal: 'PayPal Account hinzufügen',
    addDomesticCard: 'Bankverbindung hinzufügen',
    numberCard: 'Kontonummer',
    numberCard_placeholder: 'z.B.: 1234 5678 9000 1234',
    paypalId: 'PayPal Account',
    paypalId_placeholder: 'Name, @username, Email, oder Telefonnummer',
    nameCard: 'Vollständiger Name, wie auf der Karte aufgeführt',
    nameCard_placeholder: 'z.B.: Max Mustermann',
    bankSelection: 'Bank auswählen',
    bankSelection_placeholder: 'Bitte, wähle eine Bank aus',
    expDate: 'Gültigkeitsdatum',
    expDate_placeholder: 'MM/YY',
    cvv: 'Kartenprüfnummer',
    cvv_placeholder: 'z.B.: 123',
    Validation: {
      equiredCardType: 'Ungültige Kartennummer',
      requiredCardLen: 'Kartennummer muss mindestens 16 Zeichen lang sein',
      requiredCardExpDate: 'Ungültiges Gültigkeitsdatum',
      requiredCardCVC: 'Ungültige Kartenprüfnummer',
      required: 'Bitte, fülle die Pflichtfelder aus',
    },
    Action: {
      addCard: 'Karte hinzufügen',
      addAccount: 'Account hinzufügen',
      delete: 'Löschen',
      saveCard: 'Speichern',
      back: 'Zurück',
    },
  },
  History: {
    Filter: {
      category: 'Kategorie',
      status: 'Status',
      startDate: 'Startndatum',
      endDate: 'Enddatum',
      filterTxt: 'Filtern nach',
    },
    Status: {
      reject: 'Verpasste Anrufe',
      pending: 'Warten auf Bestätigung',
      unknown: 'Unbekannt',
      completed: 'Beendet',
      canceled: 'Abgebrochen',
    },
    Table: {
      createdAt: 'Datum & Zeit',
      displayName: 'Anleiter',
      type: 'Typ',
      during: '\tZeit',
      status: 'Status',
      priceAmount: 'Preis',
      courseRoomDate: 'Raumzeit',
    },
    Detail: {
      detailTxt: 'Aktivitäten Detail',
      paymentInfo: 'Aktivitäten Info',
      createdAt: 'Datum & Zeit',
      status: 'Anrufstatus',
      package: 'Anrufpaket',
      callTxt: 'Anruf',
      courseTxt: 'Kurs',
      during: 'Dauer',
      typeOfService: 'Art des Dienstes',
      advisor: 'Anleiter',
      teacher: 'Lehrer',
      cash: 'Preis',
      numberOfSession: 'Anzahl der registrieten Sitzungen',
      statusOfCourse: 'Status des Kurses',
    },
    Rating: {
      ratingTxt: 'Bewertungen & Kommentare',
      yourRating: 'Deine Bewertungen',
      aboutAdvisor: 'Kommentare über Anleiter',
    },
  },
  Transaction: {
    Filter: {
      category: 'Kategorien',
      status: 'Status',
      time: 'Datum',
      startDate: 'Vom',
      endDate: 'bis zum',
      filterTxt: 'Filtern nach',
    },
    Status: {
      reject: 'Verpasster Anruf',
      pending: 'Ausstehende Bestätigung',
      unknown: 'Unbekannt',
      incomplete: 'Unbestätigt',
      completed: 'Bestätigt',
      refund: 'Rückerstattung',
      expired: 'Abgelaufen',
      canceled: 'Abgebrochen',
      declined: 'Abgelehnt',
      processing: 'in Bearbeitung',
    },
    Table: {
      createdAt: 'Datum & Zeit',
      displayName: 'Anleiter',
      coin: 'Unitz Coin',
      status: 'Status',
      priceAmount: 'Gesamtbetrag',
      package: 'Paket',
      detailTransaction: 'Transaktionsdetails',
    },
    Detail: {
      transactionInfo: 'Zahlungsinformation',
      paymentInfo: 'Zahlungsinformation',
      paySuccess: 'Zahlung erfolgreich',
      paymentDetail: 'Zahlungsdetails',
      call: 'Jetzt anrufen',
      createdAt: 'Zeitpunkt der Transaktion',
      transactionId: 'Transaktions-ID',
      buyPackage: 'Anrufpaket kaufen',
      buyBooking: 'Buchungsservice kaufen',
      buyCourse: 'Kurs kaufen',
      status: 'Status',
      package: 'Anleiter-Paket',
      displayName: 'Anleiter',
      typeOfTransaction: 'Transaktionstyp',
      typeOfService: 'Servicetyp',
      serviceDetail: 'Details',
      customer: 'Kunde',
      paymentResource: 'Payment Resource',
      cash: 'Zahlungsbetrag',
      totalCash: 'Gesamtbetrag',
      promotion: 'Werbeaktion',
    },
    payments: {
      labelsByType: {
        card: 'Visa/master Card',
        wallet: 'Unitz Portmonee',
        payment: 'EC Karte',
      },
    },
  },
  Booking: {
    advisorTitle: 'Anleiter',
    namePackage: 'Preis des %{time} Minuten Paket',
    selectPackage: 'Wähle ein Paket',
    freeFiveMinutes: 'Die ersten drei Minuten sind kostenlos!',
    checkout: 'Bezahlen',
    checkoutMethod: 'Zahlungsmethode',
    methodPayment: {
      checkout: 'Bezahlen',
      checkoutMethod: 'Zahlungsmethode',
      visaOrMaster: 'Visa/Master Cards',
      bankCard: 'Bank Karte',
      eWallet: 'e-Wallet',
      momo: 'Momo',
    },
    PaymentEditCard: {
      headerTitle: 'Karteninformationen ändern',
      cardName: 'vollständiger Name, wie auf der Karte aufgeführt',
      cardExpDate: 'Gültigkeitsdatum',
      setDefault: 'Als Hauptkarte festlegen',
      newCard: 'Neue Karte hinzufügen',
      newCardName: 'Name auf der Karte',
      newCardNumber: 'Kartennummer',
      newCardExp: 'MM/YY',
      newCardCVV: 'Kartenprüfnummer',
      confrim: 'Bestätigen',
      requiredCardType: 'Ungültige Kartennummer',
      requiredCardLen: 'Kartennummer muss mindestens 16 Zeichen lang sein',
      requiredCardExpDate: 'Ungültiges Gültigkeitsdatum',
      requiredCardCVC: 'Ungültige Kartenprüfnummer',
      required: 'Bitt, füll die Pflichtfelder aus',
    },
    checkoutStatus: {
      title: 'Transaktionsresultat',
      code: 'Transaktionscode',
      timeTransaction: 'Transaktionszeitpunkt',
      call: 'Jetzt anrufen',
      detail: 'Details',
      success: 'Zahlung erfolgreich',
      fail: 'Zahlung fehlgeschlagen',
      pending: 'Ausstehende Bestätigung',
      congratulations: 'Das Paket wurde erfolgreich bezahlt!',
      waitingForSupport: 'Die Verifikations der Bezahlung kann einen Moment dauern, bitte warten...',
      or: 'oder',
      backHome: 'Zurück zur Startseite',
      canceled: 'Transaktion wurde abgebrochen',
      processing: 'Deine Transaktion wird bearbeitet, bitte warten...',
      completed: 'Transaktion erfolgt',
      declined: 'Transaktion konnte nicht erfolgen. Bitte kontrolliere die Zahlungsinformationen.',
    },
    AmountInvalid: 'Der Betrag entspricht nicht dem Mindesttransaktionsbetrag. Bitte gebe den vollen Betrag an.',
  },
  AdvisorApp: {
    Onboarding: {
      Skip: 'Überspringen',
    },
    Login: {
      Login: 'Anmelden',
      loginWith: 'Anmelden mit',
      fillInfo: 'Gebe deine Accountinformationen und dein Passwort ein',
      userName: 'Benutzername',
    },
  },
  Tabbar: {
    Home: 'Home',
    New: 'Entdecken',
    Wallet: 'Portmonee',
    Monetization: 'Einkommen',
    Account: 'Account',
    Calender: 'Mein Kalender',
  },
  HomeAdvisor: {
    welcomeBack: 'Hi, ',
    readyAdvise: 'Bereit anrufe entgegenzunehmen',
    withdraw: 'Abheben',
    Monetization: 'Einkommen',
    Rate: 'Bewertung',
    timeAvg: 'Minuten / Diese Woche',
    On: 'Bereit',
    Off: 'Nicht verfügbar',
    setPrice: 'Festpreis für Pakete',
    setPriceTxt: 'Preis festlegen',
    namePackage: '%{time} Minuten Paket',
    createPackage: 'Neuen Preis hinzufügen',
    newPackage: 'Neuer Paketpreis',
    editPackage: 'Paketpreis ändern',
    perPrice: 'Preis pro Einheit',
    totalPrice: 'Gesamtbetrag',
    numTime: 'Minutenanzahl',
    defaultMoney: '0 vnd',
    date: {
      Monday: 'Mo',
      Tuesday: 'Di',
      Wednesday: 'Mi',
      Thursday: 'Do',
      Friday: 'Fr',
      Saturday: 'Sa',
      Sunday: 'So',
    },
    control: 'Kontrolle',
    edit: 'Preis bearbeiten',
    delete: 'Preis Paket löschen',
    selectioPackage: 'Paket',
    acceptVideoCall: 'Anrufe von %{name} werden durchgestellt',
    rejectVideoCall: 'Anrufe von %{name} werden abgelehnt',
    acceptAdvise: 'Du bist bereit Anrufe anzunehmen',
    rejectAdvise: 'Du nimmst momentan kein Anrufe an',
    kindCall: '%{name} Anruf',
    kindCallVideo: 'Videoanruf',
    kindCallVoice: 'Sprachanruf',
    kindCallNotificationVideo: 'videoanruf',
    kindCallNotificationVoice: 'sprachanruf',
    newPackagePrice: 'Paketepreis',
    notAllowAccount: 'Der Account wurde noch nicht bestätigt',
    noServiceYet: 'Dein Account ist momentan nicht verfügbar',
    sendAuthentication: 'Account-Verifikation senden',
    AuthenticationDescription: 'Bist du fertig damit, deine Informationen in %{account} und %{info} einzutragen?',
    Account: 'Account',
    Info: 'Profil',
    ConfirmAndSend: 'Bestätigen und abschicken',
    Back: 'Zurück',
    SendApprovalSuccess: 'Anfrage auf Accountbestätigung erfolgreich abgeschickt!',
    SendApproval: 'Anfragebestätigung',
  },
  AdvisorVideoReceiver: {
    reject: 'Ablehnen',
    accept: 'Antworten',
    speaker: 'Lautsprecher',
    microOn: 'Mikro an',
    microOff: 'Mikro aus',
    rotateCamera: 'Kamera umdrehen',
    endCall: 'Anruf beenden',
    videoOn: 'Video an',
    videoOff: 'Video aus',
    reportCall: 'Anruf melden',
    callSuccess: 'Anruf erfolgreich',
    callFail: 'Anruf fehlgeschlagen',
    callInfo: 'Anrufinformationen',
    callPackage: 'Anrufpaket:',
    callPackageTime: 'Anrufdauer:',
    callPackagePrice: 'Preis pro Minute:',
    callPackageTotal: 'Preis:',
    pricePerMin: '%{currency}/Minute',
    goBack: 'Zurück',
    separate: 'Splitscreen',
    collapse: 'Minimieren',
    reconnect: 'Die Verbindung von %{user} ist nicht stabil. Bitte, warte einen Moment!',
    disconnect: 'Der Anruf wurde aufgrund von Verbindungsproblemen beendet. FÜr weitere Unterstützung wende dich an den Kundenservice!',
  },
  AdvisorWallet: {
    myWallet: 'Mein Portmonee',
    walletBalance: 'Aktuelles Guthaben',
    walletAvailabeBalance: 'Verfügbares Guthaben',
    linkBankDesc: 'Verbinde dein Bankkonto, damit du dein Geld direkt von der Applikation auf dein Konto bekommst.',
    linkBankNow: 'Verbinde dein Bankkonto ᐳ',
    linkBankTitle: 'Verbundene Kontos',
    labelBankName: 'Kontoinhaber',
    bankName: 'Gibi deinen vollen Namen ein',
    labelBankNumber: 'Kontonummer',
    bankNumber: 'Gib deine IBAN ein',
    bankCode: 'BIC',
    addBank: 'Konto hinzufügen',
    listAccount: 'Kontoliste',
    verify: 'Verifizieren',
    transferToAccount: 'Geld auszahlen',
    historyTransaction: 'Transaktionsprotokoll anschauen',
    history: 'Meine Transaktionen',
    transfer: 'Transfer',
    typeMoney: 'Betrag in (vnd) eintragen',
    accountReceiver: 'Begünstigten Konto',
    continue: 'Weiter',
    transferSuccess: 'Geldtransfer erfolgt!',
    listBank: 'Bankenliste',
    searchBank: 'Bank suchen',
    addCardSuccess: 'Karteninformationen erfolgreich hinzugefügt',
    addCardFail: 'Hinzufügen der Karteninformationen fehlgeschlagen',
    payout: 'Auszahlen',
    balance: 'Kontostand: %{balance}',
    required: 'Bitte, fülle die Pflichtfelder aus',
    moreThanMoney: 'Der Betrag muss größer als %{val} sein',
    notValidAmount: 'Der Transferbetrag darf dein aktuelles Guthaben nicht übersteigen',
    bankEmptyList: 'Im Moment sind keine Banken verbunden',
    historyTransactionEmpty: 'Transaktionsprotokoll ist leer',
    incomeEmpty: 'Kein Einkommensprotokoll verfügbar',
    requiredPrice: 'Bitte gib das gewünschte Paket an',
    minPrice: 'Paketpreis-Minimum %{amount}',
    maxPrice: 'Paketpreis-Maximum %{amount}',
    transferError: 'Transfer error',
    bankNamePlaceholder: 'Gib deinen vollständigen Namen, so wie er der Bank vorliegt, an.',
    bankNumberPlaceholder: 'Gib hier deine IBAN ein',
    infoText: 'Transaktionsinfo',
    typeText: 'Transaktiontyp',
    code: 'Transaktionskennung',
    timeTxt: 'Transaktionzeit',
    statusTxt: 'Status',
    infoTransaction: 'Zahlungsinformation',
    total: 'Auszahlungsbetrag',
    fee: 'Gebühr',
    amountAfter: 'Gesamtbetrag',
    balanceAvailable: 'Kontostand nach Transaktion',
    describeDeposit: 'Bitte, fülle dein Unitz-Portmonee auf, um die Transaktion durchführen zu können.',
    walletName: 'Unitz-Portmonee',
    depositAdd: 'Auffüllen',
    exchangePayment: 'Zahlungsmethode ändern',
    method: 'Zahlungsmethode',
    type: 'Auf mein Bankkonto auszahlen',
    minValue: 'Der Mindestebetrag ist %{num}',
    maxValue: 'Der Maximalbetrag ist %{num}',
    account: 'Empfängerkonto',
    reason: 'Grund',
    status: {
      processing: 'Bestätigung ausstehend',
      completed: 'Bestätigt',
      canceled: 'Abgebrochen',
      declined: 'Abgelehnt',
    },
    detail: 'Transaktionsdetails',
    checkoutStatus: {
      canceled: 'Transaktion wurde abgebrochen',
      processing: 'Deine Transaktion wird bearbeitet, bitte warte einen Moment.',
      completed: 'Deine Transaktion wurde erfolgreich bearbeitet',
      declined: 'Die Transaktion wurde abgelehnt. Bitte, kontrolliere die Zahlungsinformationen.',
    },
    withdrawError: 'Auszahlungsanfrage fehlgeschlagen',
    withdrawSuccess: 'Wir haben deine Auszahlungsanfrage erhalten. Die Auszahlungen werden zwischen dem fünften und zehnten Tag des nächsten Kalendermomats für den vorherigen Kalendermonat bearbeitet und ausgezahlt.',
    support: 'Hilfe',
    walletStatus: 'Status',
    linking: 'Bankkarte verknüpfen',
    linked: 'Bankkarte verknüpft',
    unlink: 'Bankkarte trennen',
    setCardDefault: 'Karte als Standardkarte festlegen',
    setCardDefaultSuccess: 'Standardkarte festgelegt!',
    setCardDefaultFail: 'Festlegen der Standartkarte fehlgeschlagen!',
    unlinkSuccess: 'Bankkarte erfolgreich getrennt!',
    unlinkFail: 'Trennen der Karte fehlgeschlagen!',
  },
  AdvisorInCome: {
    titleText: 'Einkommen',
    advisorNum: '%{num} Anrufe',
    inComeToday: 'Heute',
    inComeYesterday: 'Gestern',
    inComeInWeek: 'Diese Woche',
    inComeInMonth: 'Diesen Monat',
    inComeDetail: 'Details zum Einkommen',
    advisorNumDetail: '%{num} Anzahl der geführten Telefonate',
    inComeTextPackage: '%{num} Minuten-Paket',
    inComeTextTime: '%{num} Minuten',
    titleTextTotal: 'Ggesamteinkommen',
    statistical: 'Statistiken',
    summary: 'Übersicht',
    chart: {
      today: 'Heute',
      yesterday: 'Gestern',
      week: 'Diese Woche',
      month: 'Diesen Monat',
    },
    totalFrom: 'Gesamteinkommen von %{start} bis %{end}',
    perMount: 'Durchschnitt: %{num} vnd/call',
    perTime: 'Durchschnitt: %{num} mins/call',
    collapse: 'Weniger anzeigen',
    more: 'Mehr anzeigen',
    callVideo: 'Videoanruf',
    callVoice: 'Sprachanruf',
    callRoom: 'Kurs',
    fromDate: 'Von %{start} bis %{end}',
    chartView: {
      day: 'Tag',
      week: 'Woche',
      month: 'Monat',
    },
    totalDay: 'Gesamtes Tageseinkommen',
    numAdvisorTxt: 'Anzahl der angenommenen Anurfe',
    numAdvisor: '%{num} Anrufe',
    numCourse: '%{num} Sitzungen',
    fromDay: 'Von %{start} bis %{end}',
    rangeWeek: {
      0: 'So',
      1: 'Mo',
      2: 'Di',
      3: 'Mi',
      4: 'Do',
      5: 'Fr',
      6: 'Sa',
    },
  },
  Calendar: {
    Today: 'Heute',
    Yesterday: 'Gestern',
    Tomorrow: 'Morgen',
    Week: 'Diese Woche',
    Month: 'Diesen Monat',
    Day: 'Tag',
    _Week: 'Woche',
    _Month: 'Monat',
    Agenda: 'Terminkalender',
    Date: 'Datum',
    Time: 'Zeit',
    Event: 'Veranstaltung',
    Allday: 'Ganztägig',
    noEventsInRange: 'Keine Sitzung in diesem Zeit',
    FilterByCourses: 'Nach Kursen Filtern',
  },
  Validation: {
    accountName: 'Der Accountname muss mindestens 6 Buchstaben lang sein',
    accountNumber: 'Die Accountnummer ist ungültig',
    fullName: 'Der vollständige Name muss mindestens 6 Buchstaben lang sein und darf keine Zahlen oder Sonderzeichen beinhalten.',
    phoneNumber: 'Die Telefonnummer ist ungültig',
    identify: 'Die Identifikationsnummer ist ungültig',
    passport: 'Das Passwort ist ungültig',
    taxNumber: 'Die Steuernummer ist ungültig',
    email: 'Die Emailadresse ist ungültig',
    httpLink: 'Die URL-Adresse ist ungültig',
  },
  Alert: {
    accept: 'Akzeptieren',
    reject: 'Ablehnen',
  },
  AdvisorNotification: {
    headerTitle: 'Benachrichtigungen',
    emptyList: 'Es gibt momentan keine Benachrichtigungen, komm später wieder',
    All: 'Alle',
    UnSeen: 'Ungelesen',
    Seen: 'Gelesen',
    detail: 'Detail',
  },
  AdvisorReview: {
    headerTitle: 'Bewertungen und Rezensionen',
    userReview: 'Benutzerrezensionen',
    numReview: '%{num} Rezensionen',
    collapse: 'Weniger anzeigen',
    more: 'Mehr anzeigen',
    emptyList: 'Keine Bewertungen oder Rezensionen',
    question: 'Möchtest du ein Kommentar für einen Anleiter dalassen?',
    place_holder: 'Hier kannst du deine Meinung schreiben!',
    rating_success: 'Bewertung erfolgreich',
    Action: {
      confirm: 'Bestätigen',
      cancel: 'Abbrechen',
    },
  },
  AdvisorBonusMoney: {
    point: 'Auszeichnungen',
    transfer: 'Portmonee auffüllen und auszahlen',
    task: 'Aufgaben',
    emptyList: 'Keine neuen Aufgaben, komm später wieder',
  },
  AdvisorVoiceStart: {
    callInfoTxt: 'Du hast %{duration} Gesprächsminuten mit deinem Anleiter',
    callPolicyTx: 'Der Anruf erfolgt über eine sichere Verbindung. Unitz speichert keine Anrufdaten, ohne deine Einwilligung',
    videoCallTxt: 'Sprachanruf',
  },
  AdvisorVoiceCall: {
    title: 'Anruf wurde beendet',
    userReaction: 'Warst du mit dem Anruf zufrieden?',
    rejectCallText: 'Wurde die Verbindung unterbrochen?',
    reCall: 'Zurückrufen',
    ringingStatusTxt: 'Dein Anleiter wird kontaktiert -Bitte warte einen Moment. Der Anruf wird erst nach Verbindungsaufbau berechnet.',
    incommingStatusTxt: 'Eingehender Sprachanruf',
    initingStatusTxt: 'Verbindungsaufbau...',
    callReportTxt1: 'Du hast den Anruf mit',
    callReportTxt2: ' deinem Anleiter beendet:',
    callReviewLabel: 'Teile uns deine Meinung über dein letztes Telefonat mit.',
    callReviewBadLabel: 'Unzufrieden',
    callReviewGoodLabel: 'Sehr zufrieden',
    callReviewSubmitBtn: 'Senden',
    reviewTextPlaceholder: 'Was können wir verbessern?',
    finishTxt: 'Fertig',
    finishDesc: 'Bitte, lass uns deine Meinung zu deinem letzten Anruf wissen',
    ratingGood: 'Sehr zufrieden',
    ratingBad: 'Unzufrieden',
    finishNote: 'Was sollte verbessert werden?',
    finishSend: 'Senden',
    finishBuyMore: 'Mehr Pakete holen',
    exit: 'Schließen',
    noAnswer: 'Keine Antwort',
    back: 'Zurück',
    connectionDisconnect: 'Die Verbindung wurde aufgrund eines Netzwerkfehlers unterbrochen',
    reportCall: 'Fehlermeldung an Unitz übermitteln',
    close: 'Schließen',
    callStatus: {
      completedLabel: 'Cuộc gọi kết thúc',
      declinedLabel: 'Cuộc gọi bị từ chối',
      missedLabel: 'Cuộc gọi bị nhỡ',
      earlyEndLabel: 'Cuộc gọi kết thúc sớm',
    },
    finishReview: 'Nhận xét',
    callVideo: 'Gói video',
    callVoice: 'Gói thoại',
  },
  AdvisorVoiceReceiver: {
    reject: 'Ablehnen',
    accept: 'Annehmen',
    speaker: 'Lautsprecher',
    microOn: 'Mikro An',
    microOff: 'Mikro Aus',
    rotateCamera: 'Kamera umdrehen',
    endCall: 'Anruf beenden',
    videoOn: 'Video An',
    videoOff: 'Video Aus',
    reportCall: 'Anrufbericht',
    callSuccess: 'Anruf erfolgreich',
    callFail: 'Anruf unterbrochen',
    callInfo: 'Anrufzusammenfassung',
    callPackage: 'Anruf Paket:',
    callPackageTime: 'Anrufdauer:',
    callPackagePrice: 'Preis pro Minute:',
    callPackageTotal: 'Gesamtbetrag:',
    pricePerMin: '%{currency}/Minute',
    goBack: 'zur Startseite',
    separate: 'Split screen',
    collapse: 'Minimieren',
    reconnect: 'Die Verbindung zu %{user} ist nicht stabil. Bitte, warte einen Moment!',
    disconnect: 'Der Anruf wurde aufgrund eines Netzwerkfehlers unterbrochen',
  },
  UserTabbar: {
    Home: 'Home',
    Category: 'Kategorien',
    Like: 'Favoriten',
    Account: 'Account',
    Calendar: 'Kalender',
  },
  StatusAdvisor: {
    Online: 'Online',
    Offline: 'Offline',
  },
  SearchUser: {
    placeHolder: 'Anleiter finden',
    suggest: 'Vorschläge',
    done: 'Fertig',
    result: '%{num} Anleiter für dich gefunden',
    emptyList: 'Finde deinen Anleiter',
    notResult: 'Keinen Anleiter gefunden',
  },
  VideoCallPage: {
    SectionAction: {
      split: 'Split screen',
      collapse: 'Minimieren',
      note: 'Notizen',
      record: 'Aufnahme',
      like: 'Like',
      setting: 'Einstellungen',
      participants: 'Teilnehmer',
      message: 'Nachricht',
    },
    Initing: {
      calling: 'Anrufen',
    },
  },
  UserPayment: {
    advisorTitle: 'Anleiter',
    confirmText: 'Zahlung bestätigt',
    payment: 'Jetzt zahlen',
    paymentCourse: 'Zahlen für',
    of: 'von',
    payFor: 'für %{time} Minuten des Anrufpakets an',
    walletName: 'Unitz Portmonee',
    walletIconLabel: 'Unitz Portmonee',
    walletIcon: 'Unitz Portmonee: %{amount}',
    addPaymentMethod: 'Neue Zahlungsmethode hinzufügen',
    transactionDetail: 'Transaktionsdetails',
    amountBefore: 'Preis',
    discount: 'Rabatt',
    amountAfter: 'Gesamtbetrag',
    serviceType: '%{kind} Anruf',
    packageName: '%{time} Minuten Paket',
    titleTopup: 'Wenn du dein Portmonee um %{amount} auffüllst',
    topUpDesc: '',
    depositNow: 'Jetzt auffüllen',
    topUpTxt: 'Auffüllen',
    amount: 'Betrag',
    topUpUseful: 'Fülle dein Portmonee auf, um ohne Unterbrechungen Anrufe verlängern zu können',
    discountTxt: 'Die ersten drei Minuten sind kostenlos!',
    emptyTransactionHistory: 'Momentan keine Transaktionen',
    paymentMyWallet: 'Aus dem Unitz Portmonee bezahlen',
    linkBank: 'Verbinde deine Bankkarte für die nächste Besorgung.',
    learnMore: 'Mehr erfahren',
    typePackage: 'Package type',
    typeService: 'Type of call',
    duration: 'Time',
    time: 'Date',
    enterVoucherCode: 'Please enter voucher code',
    invalidVoucher: 'Invalid voucher, please try again!',
  },
  UserPaymentMethod: {
    titleTxt: 'Zahlungsmethode',
    listCard: 'Meine Bankkarten',
    listCardOther: 'Andere Zahlungsmethoden',
    ATM: 'EC-Karte / Online-Banking',
    VisaCard: 'Debit oder Kredit Karte',
    momoWallet: 'MoMo Wallet',
    zaloPay: 'Zalopay',
    methodSaved: 'Zahlungsmethode gespeichert',
    addCard: 'Neu Karte hinzufügen',
    selected: 'Auswählen',
    exchangeCard: 'Update',
    emptyPaymentMethod: 'Keine Zahlungsmethode ausgewählt.',
    paypal: 'Paypal',
  },
  PurchaseDetail: {
    title: 'Transaktionsdetails',
    waitForConfirm: 'Warten auf Bestätigung des Kreditinstituts/Zahlungsdienstes',
    completed: 'Transaktion bestätigt',
    waitCompleted: 'Ausstehende Transaktion',
    sourceMoney: 'Ursprung',
    infoTransaction: 'Transaktionsinformation',
    total: 'Preis',
    totalPerSession: 'Preis (pro Sitzung)',
    discount: 'Rabatt',
    amountAfter: 'Gesamtbetrag',
    status: {
      processing: 'Ausstehende Bestätigung',
      completed: 'Bestätigt',
      refund: 'Rückerstattung',
      canceled: 'Abgebrochen',
      declined: 'Abgelehnt',
    },
  },
  TopUpPayment: {
    amountBalance: 'Guthaben',
    descDiscount: 'Bekomme jetzt ein Bonus von %{percent}%, wenn du dein Guthaben um einen Betrag von %{amount} oder mehr auffüllst!',
    source: 'Ursprung',
    deposit: 'Auffüllen',
    exchange: 'Umstauschen',
    currency: 'EUR',
    infoText: 'Transaktionsinformation',
    typeText: 'Transaktionsart',
    code: 'Transaktionskenncode',
    timeTxt: 'Transaktionszeitpunkt',
    statusTxt: 'Status',
    infoTransaction: 'Zahlungsinformation',
    total: 'Zahlungsbetrag',
    discount: 'Rabatt',
    amountAfter: 'Gesamtbetrag',
    balanceAvailable: 'Füllstand deines Portmonees nach der Transaktion',
    notValidAmount: 'Unzureichender Füllstand',
    describeDeposit: 'Bitte, fülle dein Unitz-Portmoee auf, um die Transaktion durchzuführen',
    walletName: 'Unitz-Portmonee',
    depositAdd: 'Auffüllen',
    exchangePayment: 'Zahlungsmethode ändern',
    method: 'Zahlungsmethode',
    type: 'Unitz-Portmonee auffüllen',
    minValue: 'Mindestbetrag: %{num}',
    maxValue: 'Höchstbetrag: %{num}',
  },
  ServiceCallPackage: {
    buyPackage: 'Paket erwerben',
    desc: '5 Minuten übrig. Möchtest du ein weiteres Paket erwerben?',
    cancel: 'Später',
    accept: 'Mehr Pakete kaufen',
    payment: 'Bezahlen',
    continue: 'Weiter',
    confirmTxt: 'Zahlungsbestätigung',
    amountBefore: 'Preis',
    discount: 'Rabatt',
    total: 'Total',
    status: {
      processing: 'Ausstehende Bestätigung',
      completed: 'Bestätigt',
      canceled: 'Abgebrochen',
      declined: 'Abgelehnt',
    },
  },
  ProfileUser: {
    infoProfile: 'Accountinformation',
    message: 'Nachrichten',
    fullName: 'vollständiger Name',
    userInformation: 'User-Information',
    phoneNumber: 'Telefonnummer',
    email: 'Email',
    birthday: 'Geburtsdatum',
    personalIdentification: 'Persönliche ID',
    typeOfIdentity: 'Art der Identifikation',
    identifierNumber: 'Identifikationsnummer',
    required: 'Bitte, fülle die Pflichtfelder aus',
    success: 'Aktualisierung erfolgreich ',
    fail: 'Aktualisierung fehlgeschlagen. der Support wurde benachrichtigt ',
    callHistory: 'Aktivitätenprotokoll',
    transactionHistory: 'Meine Transaktionen',
    detailTransaction: 'Transaktionsdetails',
    subCallHistory: 'Anrufe, die du geführt hast',
    emptyCallHistory: 'Noch keine Anrufe',
    listCard: 'Deine Karten',
    setting: 'Einstellungen',
    subSetting: 'Sprache festlegen, Favoriten, Ausloggen',
    changePassword: 'Passwort ändern',
    changeCategory: 'Favoriten festlegen',
    language: 'Sprach',
    currentPasswordLabel: 'Aktuelles Passwort',
    oldPasswordLabel: 'Aktuelles Passwort',
    newPasswordLabel: 'Neues Passwort eingeben',
    confirmPasswordLabel: 'Neues Passwort bestätigen',
    passwordHelper: 'Passwort für Sicherheit und Account-Login',
    passwordDescription1: 'Das Passwort muss mindestens 8 Zeichen haben',
    passwordDescription2: 'Das Passwort muss Klein- und Großbuchstaben und midestens eine Zahl beinhalten.',
    next: 'Weiter',
    done: 'Fertig',
    update: 'Aktualisieren',
    voucherLabel: 'Verpasse nicht die exclusiven Angebote von Unitz',
    share: 'App teilen',
    subShare: 'Unitz einem Freund empfehlen',
    inviteMessage: 'Lade diese App runter, um zusammen mit mir zu lernen!',
    subjectEmail: 'Einladung zur Unitz-App',
    studySchedule: 'Kursplan',
    yourStudySchedule: 'Dein Kursplan',
    cardDetail: 'Karten Details',
    unlink: 'Karte trennen',
    labelSelectUser: 'Benutzer wechseln',
    changeAvatar: 'Avatar ändern',
  },
  CallHistory: {
    detailTxt: 'Anrufdetails',
    timeCall: 'Zeit',
    advisor: 'Anleiter',
    course: 'Kurs',
    category: 'Kategorie',
    typeCall: 'Art des Anrufes',
    packageName: 'Anrufpaket',
    totalAmount: 'Preis',
    timeDuration: 'Dauer',
    review: 'Bewerten & Kommentieren',
    roomRegister: 'Registrierte Sitzungen',
    status: {
      processing: 'Bearbeiten',
      completed: 'Fertig',
      canceled: 'Beenden',
      declined: 'Ablehnen',
    },
  },
  UserWallet: {
    deposit: 'Auffüllen',
    voucher: 'Gutschein benutzen',
    giftLabel: '10% Bonus, wenn du dein Portmonee um 1,000,000 vnd oder mehr auffüllst!',
    recentTransaction: 'Kürzliche Transaktionen',
    payment: 'Bezahlung',
    amountBalance: 'Füllstand',
    seeAll: 'Alle ansehen',
    walletHistory: 'Portmonee Protokoll',
    walletWithdrawalHistory: 'Portmonee Zahlungsprotokoll',
    emptyWalletHistory: 'Portmonee Protokoll ist leer',
    status: {
      processing: 'Ausstehende Bestätigung',
      completed: 'Bestätigt',
      canceled: 'Abgebrochen',
      declined: 'Abgelehnt',
    },
    packageName: '%{time} Minuten Paket',
    topUp: 'Auffüllen mit',
    accountCardInternational: 'Debit oder Kreditkarte',
    addNew: 'Neue Karte hinzufügenAdd New card',
    linkBankNow: 'Neues Bankkonto hinzufügen ',
  },
  SettingLanguage: {
    vn: 'Tiếng Việt',
    en: 'English',
    vi: 'Tiếng Việt',
    de: 'German',
    restartMessage: 'Unitz wird automatisch neugestartet, um die Sprache zu ändern.',
    restart: 'Neustart',
    hintChangeLanguage: 'Would you like to change the default language?',
    title: 'Confirm',
    yes: 'Yes',
    no: 'No',
  },
  FilterAdvanced: {
    category: 'Kategorie',
    price: 'Preis',
    rate: 'Bewertung',
    service: 'Art des Anrufs',
    favorite: 'Favoriten',
    reset: 'Zurücksetzen',
    done: 'Fertig',
    filter: 'Filter',
    liked: 'Liked',
    notLiked: 'Noch nicht geliked',
    all: 'Alle',
    serviceType: {
      video: 'Videoanruf',
      voice: 'Sprachanruf',
      phone: 'Telefonanruf',
      chat: 'Chat',
      all: 'Alle',
    },
    statusTxt: 'Status',
    status: {
      online: 'Online',
      offline: 'Offline',
      all: 'Alle',
    },
    sessionDuration: 'Sitzungsdauer',
    sessionDurationTxt: '%{time} Minuten',
    pricingType: {
      per_session: 'Sitzung',
      per_course: 'Kurs',
    },
    sessionOccurence: {
      unlimited: 'Unbegrenzte Sitzungen',
      limited: 'Begrenzte Sitzungen',
    },
    numberOfSession: 'Sitzungsanzahl',
    tuition: 'Kurskosten',
  },
  HelpCenter: {
    titleTxt: 'Help Center',
    recentQuestion: 'Oft gestellte Fragen',
    seeAll: 'Alle ansehen',
    problemWith: 'Ich habe ein Problem mit',
    account: 'MeinemAccount',
    paymentService: 'Der Zahlung für die Anrufe',
    wallet: 'Meinem Unitz Portmonee',
    advisor: 'Dem Finden des richtigen Anleiters',
    promotion: 'Einer Werbeaktion',
    otherProblem: 'Anderen Problemen',
    helpCustomer: 'Kundenbetreuung',
    infoDesc: 'Sind diese Infos hilfreich für Dich?',
    send: 'Senden',
    request: 'Hilfe anfragen',
    sendRequest: 'Anfrage senden',
    requestTitle: 'Bitte, hilf mir mit',
    requestDesc: 'Bitte, schreibe hier deine Frage ',
    imageOption: 'Bilder (optional)',
    suggest: 'Anregungen für Unitz',
    whatProblem: 'Bei welchem Problem können wir helfen?',
    sendSuccess: 'Rezension erfolgreich erstellt',
    sendFail: 'Rezension fehlgeschlagen',
    sendRequestSuccess: 'Hilfeanfrage erfolgreich gesendet',
    sendRequestFail: 'Hilfeanfrage fehlgeschlagen',
    note: 'Lese unser Handbuch, welches wir speziell für unsere Benutzer erstellt haben!',
    learnMore: 'Mehr erfahren',
    homePage: 'Startseite',
    search: 'Suchen',
    searchingResult: 'Suchergebnisse...',
  },
  CategoryFavorite: {
    title: 'Favoriten',
    otherCategory: 'Weitere Kategorien',
    Save: 'Speichern',
    success: 'Favoriten erfolgreich geändert',
    fail: 'Änderung der Favoriten fehlgeschlagen',
  },
  New: {
    describe: 'Hier findest du nützliche Informationen, atraktive Werbeaktionen und Artikel zu neuen Features',
  },
  RatingSuggestion: {
    label1: 'Sehr schlecht!',
    label2: 'Schlecht!',
    label3: 'Normal!',
    label4: 'Gut!',
    label5: 'Sehr gut!',
    title1: "Unitz regrets the experience you've had. Please share your concerns with us so we can address it. Thank you very much!",
    title2: 'Was sollte geändert werden, um die Anrufqualität für dich zu verbessern?',
    title3: 'Was sollte geändert werden, um die Anrufquakität für dich zu verbessern?',
    title4: 'Was sollte geändert werden, um die Anrufqualität für dich zu verbessern?',
    title5: '',
    noise: 'Hintergrundgeräusche',
    reverb: 'Hall',
    lossOfSound: 'Audioverbindung bricht ab',
    echo: 'Sich wiederholende Geräusche',
    distortion: 'Der Sound ist verzerrt',
    interrupt: 'Audioübertragung wird unterbrochen',
    frozen: 'Bildübertragung friert ein',
    blur: 'Bildübertragung unscharf',
    poorColor: 'Schlechte Farbübertragung',
    underexposed: 'Video bleibt schwarz/dunkel',
    lag: 'Video laggt',
    notShowing: 'Keine Videoübertragung',
  },
  Register: {
    Register: 'Registrieren',
    emailPlaceholder: 'beispiel@domain.de',
    Step1: {
      title: 'Emailadresse importieren',
      desc: 'Benutze deinen "Account Name", um dich in der Unitz-Advisor-App einzuloggen',
    },
    Authentication: 'Authentifizierung',
    AuthenEmailInfo: 'Öffne dein Postfach, um deine Emailadresse zu bestätigen',
    GuestMode: 'Entdecken',
    GuestMessage: 'Melde dich als Gast an: Du kannst auf die App zugreifen, jedoch werden einige Funktionen begrenzt sein.',
    GuestLimitMessage: 'Die Nutzung dieser Funktion ist für Gäste begrenzt. Registriere dich als Benutzer, um diese Funktion vollständig verwenden zu können.',
  },
  Favorite: {
    emptyList: 'Noch keine Favoriten',
    teacherEmptyList: 'Noch keine Favoriten',
  },
  AdvisorCourse: {
    emptyList: 'Momentan keine neuen Kurse',
    roomActions: {
      startIn: 'Beginn in %{count}',
      inProgress: 'Laufend',
      endIn: 'Endet in %{count}',
      leave: 'Verlassen',
      enter: 'Betreten',
      close: 'Schli̲e̲ßen',
      autoJoin: 'Automatisch beitreten',
    },
    streamActions: {
      pin: 'Anheften',
      unpin: 'Lösen',
      record: 'Aufnahme',
    },
    roomStatus: {
      completed: 'Fertig',
    },
  },
  ReviewCall: {
    title: 'Über deinen Anruf',
    description: 'Bitte, bewerte unsere App!',
    suggest: 'Was kann verbessert werden?',
    advisorReview: 'Über deinen Anleiter',
    advisorReviewDesc: 'Bitte, bewerte unsere Anleiter!',
    report: 'Melden',
    textReview: 'Bitte teile uns deine Meinung mit',
    reportTxt: ' Tell us about your problems with your advisor. Unitz regrets this unexpected experience. Your report will be kept confidential. We hope you will continue to support Unitz!!',
    send: 'Senden',
    reivew: 'Rezension',
  },
  UserNotification: {
    All: 'Alle ',
    UnSeen: 'Ungesehen',
    Seen: 'Gesehen',
    Delete: 'Löschen',
    Types: {
      completed_call_user: 'Dein %{packageName} Anruf mit %{userLink} ist beendet.',
    },
    notificationEmpty: 'Benachrichtigungen leer',
    voice: 'Sprach-',
    video: 'Video-',
    call: 'Paket',
  },
  LinkCard: {
    title: 'Bankkarte verbinden',
    continue: 'Weiter',
    success: 'Karte erfolgreich hinzugefügt',
  },
  Voucher: {
    title: 'Geschenkgutschein im Wert von %{money}',
    expired: 'Ablauf am %{date}',
    use: 'Jetzt benutzen',
    voucherLabel: 'Gutscheincode eingeben',
    voucherContentLabel: 'Bitt, gib deinen Gutscheincode ein',
    codePlaceholder: 'UZ-XXXXX',
    redeem: 'Jetzt einlösen',
    RedeemStatus: {
      titleSuccess: 'Gutschein erfolgreich eingelöst',
      titleFail: 'Einlösen des Gutscheins fehlgeschlagen',
      success: 'Der Gutscheinbetrag von %{money} wurde erfolgreich deinem Unitz-Portmonee hinzugefügt.',
      fail: 'Einlösung des Gutscheins fehlgeschlagen.',
    },
    goBack: 'Zurück',
    tryAgain: 'Erneut versuchen',
    Status: {
      available: 'Verfügbar',
      unavailable: 'Nicht verfügbar',
    },
  },
  Message: {
    repliesCountLabel: {
      one: '%{count} Rückmeldung',
      other: '%{count} Rückmeldungen',
      zero: 'Keine Rückmeldungen',
    },
    repliesCollapseLabel: 'Weniger anzeigen',
    replyActionLabel: 'Antwort',
    replyingIndicatorLabel: ' antwortet...',
    messageStatus: {
      edited: '(bearbeitet)',
      removed: '(entfernt)',
    },
    tabPaneLabels: {
      rooms: 'Chatbox',
      threads: 'Threads',
      mentions: 'Erwähnungen',
    },
    messageActions: {
      edit: 'Nachricht bearbeiten',
      remove: 'Nachricht entferen',
      reply: 'Nachricht beantworten',
      pin: 'Nachricht anheften',
      setAsTopic: 'Als Thema festlegen',
      setAsQuestion: 'Als Frage festlegen',
    },
    newMessageAlertLabel: {
      one: 'Du hast eine neue Nachricht',
      other: 'Du hast %{count} neue Nachrichten',
    },
    hints: {
      EnterToSend: '"Shift" + "Enter" um in der nächsten Zeile weiterzuschreiben.',
    },
  },
  Conversation: {
    allConversation: 'Alle Unterhaltungen',
    advice: 'Bitte, erzähle mir etwas zu diesem Kurs.',
  },
  ReportIssue: {
    success: 'Anrufreport an Unitz übermittelt',
    fail: 'Anrufreport konnte nicht übermittelt werden. Versuche es bitte erneut!',
  },
  ErrorMsg: {
    Default: 'Ein Fehler ist aufgetreten. Versuche es bitte erneut!',
  },
  AppCalendar: {
    monthNames: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
    monthNamesShort: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
    dayNames: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
    dayNamesShort: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
    today: 'Heute',
  },
  LearnSchedule: {
    goToClass: 'Raum betreten',
    offClass: 'Raum verlassen',
    title: 'Kursplan',
    detail: 'Sitzungsdetails',
    upcoming: 'Bald verfügbare Kurse',
    doneCourse: 'Beendete Kurse',
    emptyList: 'Kursliste ist leer',
  },
  RoomChat: {
    Title: 'Chatroom',
    Actions: {
      Edit: 'Nachricht bearbeiten',
      Mark: 'Nachricht markieren',
      Reply: 'Nachricht beantworten',
      Copy: 'Nachricht kopieren',
      Delete: 'Nachricht löschen',
    },
    ReplyToUser: '%{userName} antworten',
    SeeComment: 'Antwort anschauen',
    Rooms: 'Räume',
    Threads: 'Threads',
    Mentions: 'Erwähnungen',
    DayOf: 'Unterrichtseinheit %{day}',
    ListLesson: 'Liste der Sitzungen',
    Leave: 'Raum verlassen',
    Participant: 'Teilnehmer',
    Advisor: 'Anleiter (%{num})',
    Student: 'Teilnehmer (%{num})',
    Hosts: 'Hosts (%{num})',
    Participants: 'Teilnehmer (%{num})',
  },
  SearchCourse: {
    placeHolder: 'Finde deinen Kurs',
    result: '%{num} Kurse für dich gefunden',
    emptyList: 'Finde deinen Kurs',
    notResult: 'Keine passenden Kurse gefunden',
  },
  DateFormat: {
    Default: 'll',
    FullDisplay: 'LT LL',
  },
  CurrencyFormat: {
    vnd: 'đ',
  },
  AdvisorScheduleBooking: {
    type: 'Paket',
    selectPackage: 'Paket auswählen',
    next: 'Weiter',
    SetSchedule: 'Kursplan festlegen',
    timeLine: 'Zeit',
    schedulePresent: 'Kursplan bald verfügbar',
    list: 'Liste der Kurspläne',
    detail: 'Kursplandetails',
    callWith: '%{service} Paket mit',
    time: '%{time} Minuten',
    timeNote: 'Der Kursraum wird 5 minuten vor Beginn geöffnet. Bitte , sei pünktlich!',
    startCall: 'Beginn',
    service: 'Paketliste',
    serviceEmpty: 'Anleiter bietet dieses Paket nicht an. Bitte , wähle ein anderes Paket.',
    scheduleEmpty: 'Kursplan leer',
    timeEmpty: 'Diese Zeit ist bei diesem Anleiter nicht verfügbar. Bitte wähle eine andere!',
    cancelSchedule: 'Kursplan wurde gecancelt',
    cancelDescUser: 'Du kannst einen Termin spätestens 1 Minute vor Beginn absagen. Nachdem du den Termin abgesagt hast, wird die der Betrag in dein Unitz Portmonee gutgeschrieben.',
    confirm: 'Bestätigen',
    back: 'Zurück',
    cancelScheduleSuccess: 'Termin abgesagt!',
    cancelScheduleFail: 'Kursplan konnte nicht gecancelt werden. Bitte, versuche es erneut!',
    cancelDescAdvisor: 'Bist du sicher, dass du diesen Termin absagen willst? Der Zahlungsbetrag wird dir in dein Unitz Portmonee gutgeschrieben.',
    status: {
      confirmed: 'Buchung bestätigt',
      completed: 'Buchung vollständig',
      missed: 'Verpasste Buchung',
      canceled: 'Buchung gecancelt',
    },
    upcoming: 'Bald verfügbar',
    booked: 'gebucht',
    add: 'Hinzufügen',
    reCall: 'Anleiter anrufen',
    reCalling: 'Anleiter anrufen',
    cancelScheduleInvalid: 'Du kannst einen Termin nicht mehr canceln, sobald der Anruf begonnen hat.',
  },
  Whiteboard: {
    toolbar: {
      markerTooltip: 'Marker',
      shapeTooltip: 'Form',
      stickyNoteTooltip: 'Haftnotiz',
      pictureTooltip: 'Bild',
      moreTooltip: 'Mehr',
      moveTooltip: 'Werkzeug bewegen',
      undoTooltip: 'Rückgängig',
      redoTooltip: 'Wiederherstellen',
      dragTooltip: 'Hand-Werkzeug',
      hideTooltip: 'Tafel verbergen',
      showTooltip: 'Tafel anzeigen',
      eraseTooltip: 'Löschen',
      eraserTooltip: 'Radiergummi',
      zoomoutTooltip: 'Herauszoomen',
      zoominTooltip: 'Heranzoomen',
      textTooltip: 'Text',
      authorTooltip: 'Author anzeigen/verbergen',
      textPlaceholder: 'Text hinzufügen',
    },
  },
  CourseDetail: {
    summary: 'Zusammenfassung',
    detail: 'Details',
    schedule: 'Kursplan',
    note: 'Notiz',
    file: 'Datei',
    support: 'Hilfe',
    review: 'Rezension',
    timeNote: 'Der Kursraum wird 5 Minuten vor Beginn geöffnet. Bitte, sei pünkltich!',
    coursePresent: 'Laufender Kurs',
    download: 'Download',
    downloadSuccess: 'Download der Datei: %{file} erfolgreich!',
    payment: 'Zahlung',
    refund: 'Rückerstattung',
    statusLesson: 'Status der Sitzung',
    viewcourse: 'Kurse ansehen',
    benefits: 'Was Teilnehmer dieses Kurses lernen werden',
    purchase: {
      limited: 'Bạn đã mua {{purchase_session}} buổi. Hiện đang còn {{no_learn_session}} buổi chưa học và sẽ kết thúc vào {{end_time}}.',
      unlimited: 'Bạn đang còn {{no_learn_session}} buổi chưa học và sẽ kết thúc vào {{end_time}}.',
    },
  },
  Audio: {
    connect: 'Verbindung wird aufgebaut ...',
  },
  AdvisorCalendar: {
    Tab: {
      booking: 'Meine Buchungen',
      course: 'Mein Kurs',
    },
    courseEmpty: 'Kursliste leer',
    form: {
      fullTime: 'Ganztägig',
      beginTime: 'Beginn',
      endTime: 'Ende',
      delete: 'Zeitraum löschen',
      message: 'Dieser Zeitraum überlappt sich mit einem andern!',
      timeValid: 'Der Endzeitpunkt muss zeitlich nach dem Startzeitpunkt liegen. Bitte, korrigiere deine Eigabe!',
    },
  },
  SEO: {
    Actions: {
      add: 'SEO Link hinzufügen',
      submit: 'abschicken',
    },
    Form: {
      newSEOLink: 'Neuer SEO Link',
      originalPath: 'Original Pfad',
      SEOPath: 'SEO Pfad',
      siteTitle: 'Titel',
      siteDescription: 'Beschreibung',
      siteImage: 'Bild für die Seite',
      SEOType: 'SEO Typ',
    },
    Table: {
      originPath: 'Origin link',
      SEOPath: 'SEO Link',
      title: 'Titl',
      description: 'Beschreibung',
      image: 'Avatar',
      type: 'Typ',
    },
    manageLinks: 'Links verwalten',
    linkDetail: 'SEO Link Detail',
    linkEdit: 'SEO Link Bearbeiten',
  },
  UserCalendar: {
    Tab: {
      booking: 'Meine Buchungen',
      course: 'Meine Kurse',
    },
  },
  RemoteConfig: {
    Booking: {
      Package: 'Paket %{package} - %{time}',
      AdvisorBookingPaid: {
        title: 'Du hast %{amount} %{kind} Anrufe mit %{user}.',
      },
      AdvisorBookingCancel: {
        title: 'Der Termin mit %{user} wurde gecancelt.',
      },
      AdvisorBookingCompleted: {
        title: 'Der Termin mit %{user} ist beendet.',
      },
      AdvisorBookingConfirmed: {
        title: 'Du hast einen neuen Termin mit %{user}.',
      },
      AdvisorBookingReminder: {
        title: 'Der Termin mit %{user} wird in %{diffMin} Minuten beginnen.',
      },
      UserBookingCancel: {
        title: 'Der Termin mit %{advisor} wurde storniert.',
      },
      UserBookingCompleted: {
        title: 'Der Termin mit %{advisor} ist beendet.',
      },
      UserBookingReminder: {
        title: 'Der Termin mit %{advisor} wird in %{diffMin} Minuten beginnen.',
      },
    },
    Course: {
      AdvisorCoursePaid: {
        title: 'Der Kurs %{course} ist beendet.',
        body: 'Du erhältst %{amount}.',
      },
      AdvisorCoursePurchase: {
        title: '%{user} wird an dem Kurs%{course} teilnehmen.',
      },
      AdvisorCourseRefund: {
        title: 'Benachrichtigung über Kurs-Abbrüche',
        body: '%{user} hat den Kurs %{course} abgebrochen.',
      },
      Purchase: {
        session: 'Zahlung %{amount} für %{per_amount} Sitzungen',
        full_session: 'Gesamtpaket: %{amount}',
        per_session: '%{session} Sitzung',
        full_session_txt: 'Gesamtpaket',
        sessionOccurence: {
          unlimited: 'Unbegrenzte Sitzungen',
          limited: 'Begrenzte Sitzungen',
        },
        sessionCount: {
          zero: '0 Sitzungen',
          one: '1 Sitzung',
          other: '%{count} Sitzungen',
        },
      },
      UserCoursePurchase: {
        title: 'Du hast den Kurs %{course} erworben.',
      },
      UserCourseRefund: {
        title: 'Kurs-Rückerstattung %{course}.',
        body: 'Unitz hat dir %{amount} in dein Portmonee zurückgezahlt.',
      },
      AdvisorCoursePostpone: {
        title: 'Announcement to postpone the opening date',
        body: 'Your course %{course} has been rescheduled to %{start_at}',
      },
      UserCoursePostpone: {
        title: 'Ankündigung bezüglich der Verschiebung des Kursbeginns',
        body: 'Dein Kurs %{course} wurde auf den %{start_at} verschoben',
      },
    },
    Room: {
      UserRoomRefund: {
        title: 'Die Sitzung %{course} kann leider nicht stattfinden.',
        body: 'Unitz hat dir %{amount} in dein Portmonee gutgeschrieben.',
      },
      UserRoomConnect: {
        title: 'Kurs %{course}',
        body: 'Sitzung hat begonnen - Dauer %{duration}.',
      },
      UserRoomReminder: {
        title: 'Kurs %{course}',
        body: 'SItzung beginnt in %{diffMin} Minuten.',
      },
      UserRoomReschedule: {
        title: 'Verschobener Beginn der Sitzung von %{course}',
        body: 'Die Sitzung wird um %{time} beginnen',
      },
      AdvisorRoomConnect: {
        title: 'Kurs %{course}',
        body: 'Sitzung hat begonnen - Dauer %{duration}.',
      },
      AdvisorRoomPaid: {
        title: 'Die Sitzung %{course} ist beendet',
        body: 'Du erhältst %{amount}',
      },
      AdvisorRoomRefund: {
        title: 'Die Sitzung %{course} findet leider nicht statt.',
        body: 'Du erhältst keine Abgeltung für diese Sitzung.',
      },
      AdvisorRoomReminder: {
        title: 'Sitzung %{course}',
        body: 'Die Sitzung beginnt %{diffMin} Minuten.',
      },
      AdvisorRoomReschedule: {
        title: 'Reschedule class time %{course}',
        body: 'Class will start at %{time}',
      },
      student: {
        zero: '0 Teilnehmer',
        one: '1 Teilnehmer',
        other: '%{count} Teilnehmer',
      },
    },
    DemoCourse: {
      AdvisorDemoCourseRequest: {
        title: 'Anfrage für Probestunde',
        body: 'Du hast eine Anfrage für eine Probestunde von %{user} erhalten',
      },
      UserDemoCourseConfirm: {
        title: 'Anfrage für eine Probestunde bestätigt',
        body: 'Dein Anfrage, den Kurs %{course} auszuprobieren, wurde bestätigt.',
      },
      UserDemoCourseReject: {
        title: 'Anfrage für eine Probestunde abgelehnt',
        body: 'Deine Anfrage, den Kurs %{course} auszuprobieren, wurde leider abgelehnt.',
      },
    },
    TrialCourse: {
      AdvisorTrialCourseRequest: {
        title: 'Anfrage für Probestunde',
        body: 'Du hast eine Anfrage für eine Probestunde von %{user} erhalten',
      },
      UserTrialCourseConfirm: {
        title: 'Anfrage für eine Probestunde bestätigt',
        body: 'Deine Anfrage, den Kurs %{course} auszuprobieren, wurde bestätigt.',
      },
      UserTrialCourseReject: {
        title: 'Anfrage für eine Probestunde abgelehnt',
        body: 'Deine Anfrage, den Kurs %{course} auszuprobieren, wurde leider abgelehnt.',
      },
    },
    Purchase: {
      UserPurchaseRemind: {
        title: 'Zahlungerinnerung',
        body: 'Die Entrichtung des Kursbeitrages für %{course} ist fällig. Bitte, entrichte den Kursbeitrag schnellstmöglich!',
      },
    },
    Chat: {
      UserChatReminder: {
        title: 'Du hast eine Nachricht von deinem Anleiter',
        body: 'Bitte öffne den Browser auf deinem Computer oder Tablet, um dir die Nachricht anzuschauen. Wir arbeiten momentan daran, die Chatfunktion in die App zu integrieren',
      },
      AdvisorChatReminder: {
        title: 'Du hast eine Nachricht von einem Teilnehmer',
        body: 'Bitte öffne den Browser auf deinem Computer ode Tablet, um dir die Nachricht anzuschauen. Wir arbeiten momentan daran, die Chatfunkton in die App zu integrieren.',
      },
    },
    Email: {
      user_contacts: [
        {
          title: 'Unitz community',
          name: 'UNITZ COMMUNITY',
          url: 'https://www.facebook.com/groups/unitz.community',
        },
        {
          title: 'Fanpage',
          name: 'UNITZ APP',
          url: 'https://www.facebook.com/UnitzApp',
        },
        {
          title: 'Zalo',
          name: 'UNITZ Co., Ltd',
          url: 'https://zalo.me/2510053737522180594',
        },
        {
          title: 'Email',
          name: 'support@unitz.app',
          url: 'mailto:support@unitz.app',
        },
        {
          title: 'Website',
          name: 'https://unitz.app',
          url: 'https://unitz.app',
        },
        {
          title: 'Telefonnummer',
          name: '0856 507 095 (Ms. Trang)',
        },
      ],
      advisor_contacts: [
        {
          title: 'Unitz community',
          name: 'UNITZ COMMUNITY',
          url: 'https://www.facebook.com/groups/unitz.community',
        },
        {
          title: 'Fanpage',
          name: 'UNITZ APP',
          url: 'https://www.facebook.com/UnitzApp',
        },
        {
          title: 'Zalo',
          name: 'UNITZ Co., Ltd',
          url: 'https://zalo.me/2510053737522180594',
        },
        {
          title: 'Email',
          name: 'supplier@unitz.app',
          url: 'mailto:supplier@unitz.app',
        },
        {
          title: 'Website',
          name: 'https://advisor.unitz.app',
          url: 'https://advisor.unitz.app',
        },
        {
          title: 'Phone number',
          name: '0856 507 095 (Ms. Trang)',
        },
      ],
      advertisementForm: '- %{courseName} wird von %{teacher} gehalten und beginnt am %{startAt} um %{startTime} GMT+1 (Berlin)',
    },
    Call: {
      Package: 'Package %{package}',
      AdvisorCallPaid: {
        title: 'You got %{amount} for the %{kind} call with %{user}.',
      },
      AdvisorCallReminder: {
        title: 'You have received a payment for a call with %{user}, please turn on the app and be ready for the call',
      },
    },
    RATCourse: {
      AdvisorRATCourseRequest: {
        title: 'Request another time',
        body: 'You have received a request for another time from %{user}',
      },
      UserRATCourseRequest: {
        title: 'Request another time',
        body: 'Your request for another time for this course %{course} has been sent.',
      },
      UserRATCourseConfirm: {
        title: 'Request for another time for this course has been approved',
        body: 'Your request for another time for this course %{course} has been approved.',
      },
      UserRATCourseReject: {
        title: 'Request for another time for this course has been denied',
        body: 'Your request for another time for this course %{course} has been denied.',
      },
    },
  },
  DemoCourse: {
    Accept: 'Akzeptieren',
    Reject: 'Ablehnen',
  },
  RequestFreeTrialLesson: {
    categoryListing: 'Wähle ein Fachgebiet, welches dich interessiert',
    targetUser: 'Für wen ist der Kurs gemacht?',
    learningLevel: 'Welches Niveau/ wie viel Erfahrung hast du?',
    requestTime: 'Wähle eine passende Zeit für dich',
    nextBtn: 'Weiter',
    registrationForm: 'Wie kann der Anleiter dich kontaktieren?',
    registration: {
      studentInfo: 'Teilnehmerdaten',
      studentFirstName: 'Nachname',
      studentLastName: 'Vorname',
      studentBirthday: 'Geburtsdatum',
      studentNote: 'Notiz',
      accountInfo: 'Thông tin tài khoản',
      accountName: 'Vollständiger Name',
      accountEmail: 'Emailadresse',
      accountPhone: 'Telefonnummer',
      accountPassword: 'Passwort',
      accountRePassword: 'Passwort wiederholen',
      agreeWith: 'Ich stimme den ',
      termAndCondition: 'Geschäftsbedingungen zu',
      and: 'und',
      requestBtn: 'möchte eine Probestunde anfragen',
      privacy: ' Datenschutzerklärung',
    },
    validation: {
      studentFirstName: 'Pflichtfeld',
      studentLastName: 'Pflichtfeld',
      studentBirthday: 'Pflichtfeld',
      studentNote: 'Pflichtfeld',
      accountName: 'Pflichtfeld',
      accountEmail: 'Pflichtfeld',
      accountPhone: 'Pflichtfeld',
      accountPassword: 'Pflichtfeld',
      accountRePassword: 'Pflichtfeld',
    },
    verifyEmail: 'Bestätige deine Emailadresse',
    verify: {
      title: 'Ein Bestätigungscode wurde an deine Emailadresse gesendet.',
      actionTxt: 'Bestätigungscode wurde versendet',
      description: 'Falls du keine Email erhalten hast, schau bitte auch in deinem Spam-Ordner nach.\n' + '      Falls du auch dort keine Email findest, nutze das unten stehende Formular, um die Email erneut zu versenden',
    },
  },
  EducationTabbar: {
    Home: 'Startseite',
    Category: 'Erkunden',
    Course: 'Kurs',
    Account: 'Account',
    Calendar: 'Kalender',
  },
  Explorer: {
    courseEmpty: 'Momentan keine Kurse verfügbar',
    filter: 'Filter',
    reset: 'Filter zurücksetzen',
    rating: 'Bewertungen',
    ratingDesc: 'Mức đánh giá',
    tuition: 'Preis',
    applyFilter: 'Anwenden',
    listCourse: 'Kursliste',
  },
  EducationCourse: {
    myCourse: 'Meine Kurse',
    featureCourse: 'Angebotene Kurse',
    viewCourse: 'Teilnehmer interessieren sich für',
    latestCourse: 'Neuste Kurse',
    favorite: 'Wunschliste',
    Sort: 'Sortieren',
    SortBy: {
      All: 'Alle',
      Upcomming: 'Bald verfügbar',
      OnProgress: 'Laufend',
      Finished: 'Beendet',
    },
  },
  TeacherProfile: {
    Category: 'Anleiter in folgenden Kategorien:',
    Chat: 'Jetzt chatten!',
  },
  CourseInfo: {
    All: 'Alle',
    studySchedule: 'Mein Kalender',
    registerTrialCourse: 'Für eine Probestunde registrieren',
    detail: 'Details anschauen',
    enrollNow: 'Einschreiben',
    retrialCourseSuccess: 'Anfrage für eine Probestunde wurde versendet',
    retrialCourseSuccessMessage: 'Deine Anfrage für eine Probestuunde wurde erfolgreich versendet. Der Anleiter wird sich bald bei dir melden!',
    retrialCourseError: 'Anfrage ist fehlgeschalgen',
    retrialCourseErrorMessage: 'Dein Anfrage für eine Probestunde ist fehlgeschlagen, bitte versuche es erneut!',
    goHome: 'Startseite',
    myCalendar: 'Mein Kalender',
  },
  ProfileStudent: {
    calendar: 'Kursplan',
    subCalendar: 'Zeiten bevorstehender Sitzungen',
    message: 'Nachrichten',
    subMessage: 'Nachrichten von Anleitern',
    notes: 'Systembenachrichtigungen',
  },
  MessageChat: {
    actions: {
      title: 'Aktion',
      Edit: 'Nachricht bearbeiten',
      Remove: 'Nachricht entfernen',
    },
  },
  Subscriber: {
    Modal: {
      title: 'Nehme jetzt an einer Probestunde teil und registriere dich anschließend für einen Kurs!',
      sub_title: 'Lerne effektiv auf Basis bewehrter und moderner Lernmethoden mit qualifizierten Anleitern für verschiedenste Fachgebiete - auf der Unitz Lernplatform!',
      your_message: 'Your messages',
    },
    Button: {
      title: 'Anmelden',
    },
    Notification: {
      success: 'Du hast dich erfolgreich registriert.',
    },
  },
  AboutUs: {
    message_success: 'Thank you for contacting us! We will get back to you within 3 working days.',
  },
  RoomCall: {
    endCall: 'End of class',
    timeLeft: 'Your class time is still left',
    continueCall: 'Still want to finish the lesson ?',
    chatDelay: 'The functionality works best in the browser, we will be rolling out the Chat feature in the app soon.',
    okText: 'OK',
    chatWithTeacher: 'Chat with teachers now',
    waitingAdvisorNote: 'You are waiting to connect with consultant %{advisor}. This is a %{package}.\n' + '\n' + "    Now you have the first 3 minutes free, after 3 minutes the call will start to be charged. If the Expert doesn't come in, you will get a refund for this package.",
    waitingUserNote: 'You are waiting to connect with your customer. This is a %{package}.\n' + '\n' + '    Users are free for the first three minutes, after 3 minutes the call will start to be charged. You need to join the call more than three minutes and longer than the user, you will get money for this package.',
  },
  TimezoneSetting: {
    notfound: 'No search results found.',
    textSearch: 'Type to search...',
  },
  RequestPermission: {
    Allow: 'Allow',
    Later: 'Later',
    Notification: {
      header: 'Unitz would like to send you notifications to:',
      content: {
        require_1: 'Make sure that you don’t miss any calls',
        require_2: 'Get notifications from your experts instantly',
        require_3: 'Quickly get payment information and refunds',
      },
      message: 'Notifications may include alerts, sounds and icon badges. This can be configured in Settings',
    },
    Camera: {
      header: 'Please allow apps to access your Camera to:',
      content: {
        require_1: 'Meet your expert in video call ',
        require_2: 'Ensure conversation with better experience',
        require_3: 'Make sure your Expert can see you to give you the most accurate advice if you are in a beauty consulting service',
      },
    },
    Voice: {
      header: 'Please allow apps to access your Microphone to:',
      content: {
        require_1: 'Make sure that your Expert can hear you clearly ',
        require_2: 'Make the conversation flow more smoothly',
        require_3: 'Ensure you are having the best experience',
      },
    },
  },
  RequestApproval: {
    note: 'Please wait a moment, we will set up a consulting account for you...',
    approvalSuccess: 'The consulting account has been set up!',
  },
  AdvisorOnboarding: {
    category: 'Category',
    basicInfo: 'Fill in basic information',
    basicInfo_2: 'Update personal information',
    infoDescription: 'The information below is public in nature, will be visible to your customers.',
    infoDescription_2: 'Complete your profile by updating information regarding experience, qualifications, and certifications',
    intro_text_example: 'Example: Over 10 years of experience in the field of meditation, transmission of life energy.',
    detail_text_example: 'Example: I have over 10 years of experience in meditation and methods for better mental health. If you want to share tips...',
    experience_example: 'Example: Over 10 years of experience in counseling psychology.',
    certificates_example: "Example: Graduated with a master's degree in psychology from Ho Chi Minh City University of Education",
    education_example: "Example: Graduated with a master's degree in psychology from Ho Chi Minh City University of Education",
    setupPackage: 'Set up consulting call',
    setupPackageDesc: 'Setting price information,\ncall time per package.',
    setupPrice: 'Price package for call %{package}',
    createAccount: 'Create an account now and start connecting with thousands of customers waiting for your consultation. ',
  },
  ZoomToolMenu: {
    Home: 'Startseite',
    ProgramsTemplates: 'Programm erstellen',
    Programs: 'Alle Programme',
    CourseTemplates: 'Kurs erstellen',
    Courses: 'Alle Kurse',
    Requests: 'Anfragen von Lehrern',
    Admins: 'Verwaltung',
    Teachers: 'Lehrer',
    Students: 'Schüler',
    Calendar: 'Stundenplan',
    MyAccount: 'Mein Konto',
    Notifications: 'Nachrichten',
    Settings: 'Einstellungen',
  },

  ZoomToolOrganization: {
    organization: 'Organisation',
    create: 'Neue Organisation hinzufügen',
    settings: 'Organisation Einstellungen und abonnements',
  },

  ZoomToolDashboard: {
    ongoing: 'Laufende Kurse',
    upcoming: 'Kurse in Vorbereitung',
    finished: 'Beendete Kurse',
    details: 'Einzelheiten',
    number_of_students: 'Gesamtnummer der Schüler',
    number_of_teachers: 'Gesamtnummer der Lehrer',
    number_of_courses: 'Gesamtnummer aller Kurse',
    alert: {
      message1: 'Willkommen zu Unitz',
      message2: 'Zhre kostenlose Version ist nur noch 13 Tage gültig melden Sie sich jetzt für eine Vollversion an.',
    },
    course: {
      zero: '0 Kurse',
      one: '1 Kurs',
      other: '%{count} Kurse',
    },
    student: {
      zero: '0 Schüler',
      one: '1 Schüler',
      other: '%{count} Schüler',
    },
    attendance: 'Anwesenheit',
    student_performance: 'Notenübersicht der gesamten Schule',
    requests: 'Anfragen von Lehrern',
    empty_request: 'Noch keine Anfrage',
    empty_calendar: 'Heute findet kein Unterricht statt',
    total: 'Total',
  },
  ZoomToolProgram: {
    title: 'PROGRAMMLISTE',
    empty_text1: 'Lassen Sie uns hier anfangen! Ein Programm besteht aus mehreren Kursen.',
    empty_text2: 'Fangen Sie damit an ein neues Programm hinzuzufügen.',
    empty_text3: 'Möchten Sie mehrere Kurse in einem Programm zusammenfügen? Sie können die Programmfunktion auch auslassen indem Sie in unseren Einstellungen die Programmfunktion ausschalten.',
    add_form_title: 'ERSTELLE EIN NEUES PROGRAMM',
    edit_form_title: 'PROGRAMM BEARBEITEN',
    success_title: 'Das Programm wurde erfolgreiche erstellt',
    success_sub_title: '',
    course_templates: 'Programm',
    course: 'Kurs',
    Detail: {
      about_title: 'Programmübersicht',
      courses: 'Kurse',
    },
    Form: {
      name: 'Name des Programm',
      name_input_placeholder: '',
      courses: 'Kurse',
      description: 'Beschreibung des Programms',
      description_input_placeholder: '',
      add_course: 'Kurs hinzufügen',
      course: {
        zero: '0 Kurse',
        one: '1 Kurs',
        other: '%{count} Kurse',
      },
      selected_course_template: 'Ausgewählter Kurs',
      search_label1: 'Existierende Kurse suchen',
      search_label2: 'suchen',
      search_placeholder: '',
    },
    Action: {
      add_program: 'Neues Programam erstellen',
      add_program_from_template: 'Create new program from existing ones',
      add_course_template: 'Neuen Kurs erstellen',
      start: 'Einrichten',
      use: 'Verwenden',
      remove: 'Löschen',
      edit: 'Bearbeiten',
      start_program_now: 'Programm einrichten',
    },
    selected_course_template: 'Selected course template',
    create_course_state_title: 'Fangen Sie damit an einen neuen Kurs hinzuzufügen',
    empty_course_state_title: 'Es gibt noch keine Kursvorlage die verwendet werden kann.',
  },
  ZoomToolProgramTemplate: {
    title: 'PROGRAMMLISTE',
    empty_text1: 'Lassen Sie uns hier anfangen! Ein Programm besteht aus mehreren Kursen.',
    empty_text2: 'Fangen Sie damit an ein neues Programm hinzuzufügen.',
    empty_text3: 'Möchten Sie mehrere Kurse in einem Programm zusammenfügen? Sie können die Programmfunktion auch auslassen indem Sie in unseren Einstellungen die Programmfunktion ausschalten.',
    add_form_title: 'NEUES PROGRAMM ERSTELLEN',
    edit_form_title: 'PROGRAMM BEARBEITEN',
    success_title: 'Das Programm wurde erfolgreiche erstellt',
    success_sub_title: '',
    course_templates: 'Programm erstellen',
    course: 'Kurs',
    Detail: {
      about_title: 'Programmübersicht',
      courses: 'Kurse',
      course_detail: 'Kursübersicht',
    },
    Form: {
      name: 'Name des Programm',
      courses: 'Kurse',
      description: 'Beschreibung des Programms',
      add_course: 'Kurs hinzufügen',
      course: {
        zero: '0 Kurse',
        one: '1 Kurs',
        other: '%{count} Kurse',
      },
      selected_course_template: 'Ausgewählter Kurs',
      search_label1: 'Existierende Kurse',
      search_label2: 'suchen',
      search_placeholder: '',
    },
    Action: {
      add_course_template: 'Erstellen Sie eine neue Kursvorlage',
      add_program_template: 'Erstellen Sie eine neue Programmvorlage',
      start: 'Einrichten',
      use: 'Verwenden',
      remove: 'Löschen',
      edit: 'Bearbeiten',
      start_program_now: 'Programm einrichten',
    },
    selected_course_template: 'Ausgewählter Kurs',
    create_course_state_title: 'Erstellen Sie jetzt einen neuen Kurs',
    empty_course_state_title: 'Es gibt noch keine Kursvorlage die verwendet werden kann.',
  },
  ZoomToolTeacher: {
    empty_text: 'Fangen Sie damit an einen Lehrer hinzuzufügen',
    report_title: 'Lehrerbericht',
    send_mail_title: 'Report des Lehrers verschicken',
    edit_teacher_title: 'Lehrer bearbeiten',
    Table: {
      title: 'LEHRER',
      teacher: 'Lehrer',
      assistant: 'Assistent/in',
      type: 'Anstellungsart',
      course_in_progress: 'Laufende Kurse',
      lesson_in_progress: 'Laufende Unterrichtseinheiten',
      attendance: 'Abwesend/Verspätet/Früher gegangen',
      status: 'An der Schule angestellt',
      add_to_course: 'Lehrer hinzufügen',
      active: 'Ja',
      inactive: 'Nein',
    },
    Attendance: {
      present: 'Anwesend',
      absent: 'Abwesend',
      late: 'Verspätet',
      early_dismissed: 'Früher gegangen',
    },
    Action: {
      view_report: 'Übersicht anschauen',
    },
  },
  ZoomToolStudent: {
    empty_text: 'Fangen Sie damit an einen Schüler hinzuzufügen',
    add_student_title: 'Schüler hinzufügen',
    report_title: 'Studentenbericht',
    send_mail_title: 'Report des Schüler verschicken',
    edit_student_title: 'Bearbeiten',
    Table: {
      title: 'SCHÜLERLISTE',
      student: 'Schüler',
    },
    Action: {
      add_student: 'Schüler hinzufügen',
    },
  },
  ZoomToolAccount: {
    title: 'KONTEN',
    create_organization: 'NEUE ORGANISATION ERSTELLEN',
    edit_organization: 'ORGANISATIONINFORMATION',
    Form: {
      organization_id: 'Firmen-id-nummer',
      organization_name: 'Organisationname',
      logo: 'Bild',
      add_logo: 'Bild hinzufügen',
    },
    ListTable: {
      name: 'Name',
      actions: 'Aktionen',
    },
    Action: {
      save: 'Speichern',
      new_account: 'Neues Konto',
      create_organization: 'ORGANISATION ERSTELLEN',
    },
  },
  ZoomToolCourse: {
    title: 'KURSELISTE',
    add_form_title: 'NEUEN KURS ERSTELLEN',
    edit_form_title: 'KURS BEARBEITEN',
    empty_text1: 'Fangen Sie damit an einen neuen Kurs hinzuzufügen.',
    empty_text2: `Sie haben im Moment noch keinen Kurs klicken Sie auf " Kurs hinzufügen "um einen neuen Kurs zu kreieren.`,
    success_title: 'Der Kurs ist erfolgreich gespeichert:',
    success_sub_title: '',
    course_type: 'Ort der Unterrichtseinheit',
    minute_per_lesson: '%{minute} Minuten / per Unterrichtseinheit',
    name: 'Name',
    edit_lesson_title: 'Lektion bearbeiten',
    UseTemplate: {
      title: 'KURS EINRICHTEN',
      choose_template_title: '',
    },
    teacher: 'LEHRER',
    Form: {
      search_placeholder: '',
      name: 'Name des Kurses',
      teacher: 'Lehrer',
      student: 'Schüler',
      duration: 'Dauer der Unterrichtseinheit',
      course_type: 'Ort der Unterrichtseinheit',
      start_date_placeholder: '',
      time: 'Zeitpunkt der Unterrichtseinheiten',
      lesson_time: 'Zeitpunkt der Unterrichtseinheit',
      online: 'Im Internet',
      offline: 'In der Klasse',
      minutes: 'Minuten',
      session_unit: '/ per Unterrichtseinheit',
      select_time: 'Zeitpunkt der Unterrichtseinheit',
      add_lesson_time_manually: 'Die Unterrichtszeiten manuel hinzufügen',
      add_photo: 'Foto hinzufügen',
      drop_photo: 'Ziehen Sie ein Foto hier hin',
      select_date: 'Datum auswählen',
      select_time: 'Zeit auswählen',
    },
    Table: {
      name: 'Name des Kurses',
      teacher: 'Lehrer',
      student: 'Schüler',
      student_number: 'Schüler',
      email: 'Email-Adresse',
      type: 'Art der Anstellung',
      startDate: 'Startdatum',
      lesson_progress: 'Laufende Unterrichtseinheiten',
      actions: '',
    },
    Action: {
      add_course_from_template: '',
      add_course: 'Neuen Kurs erstellen',
      add_more_schedule: 'Weitere Zeiten hinzufügen',
      add_teacher: 'Lehrer hinzufügen',
      add_assistant: 'Add teaching assistant',
      add_student: 'Schüler hinzufügen',
      add_a_teacher: 'Lehrer hinzufügen',
      add_a_student: 'Schüler hinzufügen',
      next: 'Nächste Seite',
      create_course_from_scratch: '',
      use_this_template: 'Verwenden',
      back: 'Zurück',
      chat_room: 'Chat room',
      view_course: 'Anschauen',
      edit_course: 'Bearbeiten',
      view_report: 'Kursübersicht',
      edit_student: 'Notenübersicht',
      edit_attendance: 'Bearbeiten',
      edit_teachers: 'Bearbeiten',
      done: 'Fertig',
      enroll_student: 'Schüler zum Kurs hinzufügen',
      manage_all_courses: 'Liste aller Kurse',
      join_room: 'Zur Klasse gehen',
    },
    Notification: {
      edit_lesson_success: 'Lektionsinformationen erfolgreich bearbeiten',
    },
    Status: {
      all: 'Alle',
      upcoming: 'Bevorstehende',
      draft: 'Bevorstehende',
      ongoing: 'Laufend',
      completed: 'Fertig',
    },
  },
  ZoomToolCourseTemplate: {
    title: 'KURSLISTE',
    add_form_title: 'NEUEN KURS ERSTELLEN',
    edit_form_title: 'KURS BEARBEITEN',
    empty_text1: 'Fangen Sie damit an einen neuen Kurs hinzuzufügen.',
    empty_text2: `Sie haben im Moment noch keinen Kurs klicken Sie auf " Kurs hinzufügen "um einen neuen Kurs zu kreieren.`,
    success_title: 'Der Kurs ist erfolgreich gespeichert',
    success_sub_title: '',
    course_templates: '',
    lesson: 'Unterrichtseinheit',
    confirm_edit_title: 'Sie haben noch nicht gespeicherte Veränderungen im Dokument',
    confirm_edit_description: '',
    confirm_delete_title: 'Löschen',
    confirm_delete_description: 'Möchten Sie dieses Kurs wirklich löschen? Alle Informationen werden dadurch gelöscht.',
    Detail: {
      about_title: 'Kursübersicht',
      materials: 'Kursmaterialien',
      lessons: 'Unterrichseinheiten',
      see_more: 'Mehr anschauen',
      see_less: 'Weniger Materialien anzeigen',
    },
    Status: {
      hasCourse: 'Der Kurs ist bereits zu folgenden Terminen gestartet:',
      emptyCourse: 'Der Kurs ist noch nicht gestartet',
    },
    Action: {
      add_template: 'Neuen Kurs erstellen',
      start_course: 'Kurs einrichten',
      view: 'Anschauen',
      save: 'Speichern',
      delete: 'Löschen',
      edit: 'Bearbeiten',
      back: 'Zurück',
      yes: 'Ja',
      cancel: 'Abbrechen',
      save_as_draft: 'Sie haben noch nicht gespeicherte Veränderungen im Dokument',
      manage_all_templates: 'Liste aller Kurse anschauen',
      start_this_course_now: 'Kurs anschauen',
      duplicate: 'Duplizieren',
    },
    Form: {
      name: 'Name des Kurses',
      lessons: 'Unterrichseinheiten',
      lesson_title: 'Titel der Unterrichtseinheit',
      cover: 'Foto ',
      cover_placeholder: 'Foto hinzufügen (16x9)',
      cover_hint: 'Wir unterstützen JPG/PNG/GIF/BMP',
      description: 'Kursbeschreibung',
      material: 'Kursmaterialien',
      material_hint: 'Hier können sie die unterschiedlichsten Formate an Kursmaterialien hochladen: *.doc, *.docx, *.pdf, *.pptx, *.mp3, *.jpeg, *.png, *.jpg',
      url_placeholder: 'URL Link hinzufügen',
      add_link: 'URL Link hinzufügen',
      done: 'Kurseinrichtung abschließen',
      cancel: 'Abbrechen',
      add_lesson: 'Neue Unterrichtseinheit hinzufügen',
      add_multi_lesson: 'Mehrere Unterrichtseinheiten hinzufügen',
      lesson: {
        zero: '0 Unterrichtseinheit',
        one: '1 Unterrichtseinheit',
        other: '%{count} Unterrichtseinheit',
      },
    },
  },
  ZoomToolMember: {
    title: 'MITARBEITER MIT ZUGRIFFSBERECHTIGUNG',
    add_user_button: 'Neuen Angestellten hinzufügen',
    start_date: 'Startdatum',
    empty_course_state_title: 'Es gibt noch keine Kurs die verwendet werden kann.',
    Role: {
      account_holder: 'Kontoinhaber',
      admin: 'Administrator',
      teacher: 'Lehrer',
      student: 'Schüler',
      student_manager: 'Studentischer Manager',
      counselling: 'Beratung',
      quality: 'Qualität',
    },
    Form: {
      search_label: 'Suchen',
      search_placeholder: 'Name oder E-Mail-Adresse',
      role_label: 'Berufsbezeichnung',
      role_placeholder: '',
      status_label: 'Zugangsberechtigung',
      status_placeholder: '',
      clear_all_button: 'Löschen',
      send_to_label: 'Schicken zu',
      send_to_me: 'Auch zu mir schicken',
      undefined: 'Nicht definiert',
    },
    Add_Form: {
      form_name: 'Neues Profil erstellen',
      first_name_label: 'Vorname',
      first_name_placeholder: '',
      last_name_label: 'Nachname',
      last_name_placeholder: '',
      date_of_birth_label: 'Geburtsdatum',
      date_of_birth_placeholder: 'YYYY-MM-DD',
      email_label: 'E-Mail-Adresse',
      email_placeholder: '',
      phone_label: 'Telefonnummer',
      phone_placeholder: ' +49 151 72 305459',
      photo_label: 'Bild',
      note_label: 'Notizen',
      note_placeholder: '',
      roles_label: 'Rollen',
      Action: {
        add_user: 'Profil hinzufügen',
        cancel: 'Abbrechen',
        see_permissions: 'Rechte anschauen',
        hide_permissions: 'Rechte schließen',
      },
      Notification: {
        add_success: 'Erfolgreich hinzugefügt',
        add_error: 'Etwas ist schief gelaufen. Bitte überprüfen Sie noch einmal Ihre Angaben!',
      },
      place_of_birth_label: 'Geburtsort',
      place_of_birth_placeholder: 'München',
    },
    View_Form: {
      form_name: 'Benutzer anzeigen',
    },
    Edit_Form: {
      form_name: 'Profil bearbeiten',
      Action: {
        edit_user: 'Profil bearbeiten',
        cancel: 'Abbrechen',
      },
      Notification: {
        edit_success: 'Änderungen erfolgreich durchgeführt',
      },
    },
    Table: {
      number_order: 'Nummer',
      full_name: 'Vor- und Nachname',
      phone_number: 'Telefonnummer',
      email: 'Email-Adresse',
      role: 'Berufsbezeichnung',
      status: {
        col_name: 'Zugangsberechtigung',
        type: {
          active: 'Zugang eingerichtet',
          disabled: 'Kein Zugang',
        },
      },
      action: '',
      Action: {
        edit: 'Bearbeiten',
      },
    },
    Action: {
      export_to_pdf: 'Als PDF herunterladen',
      send_email: 'Per E-Mail schicken',
      back: 'Zurück',
      add_email: 'Neue E-Mail-Adresse hinzufügen',
      cancel: 'Abbrechen',
      send: 'Senden',
    },
    Program: {
      ongoing: 'Laufendes Programm',
      upcoming: 'In Vorbereitung',
      finished: 'Beendes Programm',
      program: {
        zero: '0 Programme',
        one: '1 Programm',
        other: '%{count} Programme',
      },
    },
    Course: {
      ongoing: 'Laufender Kurs',
      upcoming: 'In Vorbereitung',
      finished: 'Beender Kurs',
      course: {
        zero: '0 Kurse',
        one: '1 Kurs',
        other: '%{count} Kurse',
      },
    },
  },
  ZoomToolNotification: {
    title: 'ALLE ÄNDERUNGEN IM SYSTEM',
    empty_text: 'Es gibt noch keine Notizen die angezeigt werden können',
  },
  ZoomToolCalendar: {
    empty_text: 'Es gibt noch keine Notizen die angezeigt werden können',
    prepare_lesson: 'Unterricht vorbereiten',
    course: 'Kurse',
    lesson: 'Unterrichtseinheit',
    today: `Der heutige Kalender`,
    material_reminder: `Sie haben die Unterrichtsmaterialien für diese Lektion nicht vorbereitet.`,
    Form: {
      viewBy: 'Anschauen bei',
      search: 'Suchen',
      status: 'An der Schule eingeschrieben',
      program: 'Programm',
      all: 'Alle',
      inprogress: 'Im Gange',
      student: 'Schüler',
      teacher: 'Lehrer',
    },
    roomActions: {
      modals: {
        cancelTitle: 'Das Klassenzimmer verlassen',
        cancelText: 'Sind Sie sicher Sie wollen das Klassenzimmer verlassen?',
        form: {
          lessonName: 'Name der Unterrichtseinheit',
          courseName: 'Kursnahme',
          dateTime: 'Datum der Unterrichtseinheit',
          currentTime: 'Zeitpunkt der Unterrichtseinheit',
          newTime: 'Zeitpunkt der Unterrichtseinheit',
          applyFor: 'Für die folgenden Einheiten',
          checkOptions: {
            option1: 'Diese Unterrichtseinheit',
            option2: 'Die folgenden Unterrichtseinheiten: %{count}',
            option3: 'Alle Unterrichtseinheiten',
          },
          manageTeachers: 'Lehrer',
          reason: 'Grund der Absage',
        },
      },
      buttons: {
        confirmCancel: 'Reagieren sie auf die Nachricht zur Absage der Unterrichtseinheit',
        confirmReschedule: 'Reagieren sie auf die Nachricht zur Verschiebung der Unterrichtseinheit',
        confirmSubstitutes: 'Reagieren sie auf die Anfrage einen Ersatzlehrer für die Unterrichtseinheit zu stellen',
      },
      cancel: 'Unterrichtseinheit absagen',
      reschedule: 'Unterrichtseinheit verschieben',
      manageSubstitutes: 'Ersatzlehrer für Unterrichtseinheiten beantragen',
    },
    Action: {
      reset: 'Neu Starten',
      save: 'Änderungen speichern',
    },
  },
  ZoomToolMyAccount: {
    title: 'Mein Profil',
    edit_information: 'Informationen bearbeiten',
    dob_label: 'Geburtstag',
    first_name_label: 'Vorname',
    last_name_label: 'Nachname',
    change_password: 'Passwort ändern',
    timezone: 'Zeitzone einrichten',
    region: 'Region',
    calendar: 'Kalender',
    font_size: 'Schriftgröße',
    calendar_label: 'Kalendertyp ändern',
    timezone_label: 'Zeitzone auswählen',
    font_size_label: 'Schriftgröße ändern',
    language_note: 'Möchten Sie die angezeigte Sprache in eine andere Sprache umändern?',
    timezone_note: 'Geben Sie hier ihre Zeitzone ein, damit dann in unserer Plattform Ihre lokale Zeitzone automatisch angezeigt wird.',
    region_note: 'Geben Sie hier die Region ein in der sie leben.',
    calendar_note: 'Wenn sie sich in Unitz einloggen, können Sie den folgenden Kalender Typ auswählen.',
    font_size_note: 'Dies will bitte die Schriftgröße die während der Verwendung der Lernplattform angezeigt ist.',
    font_size_unit: '',
    region_hint: 'Geben Sie hier die Region ein in der sie leben.',
    time_zone_placeholder: 'Wählen Sie Ihre Zeitzone aus',
    region_placeholder: 'Wählen Sie Ihre Region',
    calendar_placeholder: 'Wählen Sie Ihren Kalender aus',
  },
  ZoomToolSettings: {
    title: 'EINSTELLUNGEN',
    organization_name: 'Name der Organisation',
    company_logo: 'Logo der Organisation',
    class_time_rules: {
      label: 'Regeln für die Unterrichtszeit',
      tardy: 'Nach dieser Zeitspanne wird das Anmelden zur Unterrichtseinheit als verspätet angezeigt:',
      early: 'Mit dieser Zeitspanne wird das Verlassen des Unterrichtes als früher gegangen angezeigt:',
      minute: 'Minuten',
      minutes: 'Minuten',
    },
    supported_materials: 'Unterstützte Dokumentenformate',
    lessons_materials: {
      label: 'Erlaubte Zugang zu Unterrichtsmaterialien',
      option1: 'Nur bis zur momentanen Unterrichtseinheit',
      option1_1: 'Erlaube erlaube Lehrern Unterrichtseinheiten manuell einzustellen',
      option2: 'Auch eine Unterrichtseinheit im voraus anzeigen',
      option2_2: 'Erlaube Lehrern Unterrichtseinheiten manuell einzustellen',
      option3: 'Alle Unterrichtseinheiten',
    },
    program: {
      label: 'Programm überspringen',
      enabled: 'Programm überspringen',
      disabled: 'Einschalten Ausschalten',
      note: 'Ein Programm besteht aus mehreren Kursen. Suchen Sie hier aus ob sie mehrere Kurse zu einem Programm zusammenfassen möchten.',
    },
    approval: {
      label: 'Erlaube die folgenden automatischen Einstellungen',
      option1: 'Automatische Bestätigung abgesagter Unterrichtseinheiten',
      option2: 'Automatische Bestätigung wenn Unterrichtseinheiten verschiebt werden müssen',
    },
    evaluation: {
      label: 'Schüler Beurteilungen für jede einzelne Unterrichtseinheit',
      option1: 'Mitarbeit des Schülers während der Klasse',
      option2: 'Wie aktiv hat sich der Schüler im Unterricht beteiligt',
      option3: 'Noten für den Schüler einstellen',
    },
    grading_system: {
      title: 'Schülerbewertung per Kurs',
      label: 'Wahl des Notensystems',
      note: 'Wollen sie das Note nsystem umstellen?',
    },
  },
  ZoomToolFilter: {
    Status: {
      all: 'Alle',
      active: 'Aktiv',
      disabled: 'Deaktiviert',
      inprogress: 'Im Gange',
    },
  },
  ZoomToolRoomManipulation: {
    selectCamera: 'Wählen Sie eine Kamera aus',
    selectSpeaker: 'Wählen Sie einen Lautsprecher aus',
    selectMicrophone: 'Wählen Sie ein Mikrofon aus',
    mirrorVideo: 'Mein Video spiegeln',
    videoStatistic: 'Videostatistik',
    audioStatistic: 'Audio-Statistik',
    leaveAudio: 'Ton verlassen',
    hangUp: 'Auflegen',
    startAudio: 'Ton starten',
    mute: 'Stumm',
    unmute: 'Stummschalten',
    startCam: 'Kamera starten',
    showVideo: 'Videos zeigen',
    hideVideo: 'Videos ausblenden',
    startShareScreen: 'Bildschirmfreigabe starten',
    stopShareScreen: 'Beenden Sie die Bildschirmfreigabe',
    showThumbnail: 'Vorschaubilder anzeigen',
  },

  ZoomToolError: {
    default: 'Etwas ist schief gelaufen!',
    CourseTemplate: {
      create: {
        fail: 'Die Kurserstellung ist fehlgeschlagen. Bitte überprüfen Sie die Angaben noch einmal',
      },
      edit: {
        fail: 'Kursbearbeitung fehlgeschlagen. Bitte überprüfen Sie die Angaben noch einmal',
      },
    },
  },
};
