import moment from 'moment';
import _ from 'lodash';
import { durationFormatterText, formatVND, normalizeNumber } from '@vl/mod-utils/currencyFormatter';
import { TIMESTAMPTZ_FORMAT, DATE_TIME_CREATED_FORMAT } from '@vl/mod-utils/datetime';
import i18n from 'i18n-js';
import helpers, { flattenGet } from '@vl/mod-utils/flattenGet';
import { formatDateDisplay, formatFullDateDisplay } from '@vl/mod-utils/dateFormatter';
import { mergeTimeIfValid, transformUTCEvents } from '@vl/mod-utils/time';

export const COURSE_STATUS = {
  END: 'End',
  NO_STARTED: 'NoStarted',
  STARTING: 'Starting',
};

const COUNT_DOWN_TIMER_END_SECOND = 5 * 60;

export const formatter = {
  formatRoomEvent: (ctx) => (room) => {
    return {
      ...room,
      title: `${_.get(room, 'course.name')}`,
      allDay: false,
      start: moment(room.start_at).toDate(),
      end: moment(room.end_at).toDate(),
      room,
    };
  },
  formatRoomEvents: (ctx) => (rooms) => {
    return _.map(rooms, (room) => {
      return formatter.formatRoomEvent(ctx)(room);
    });
  },

  courseAvatar: (ctx) => (itemData) => {
    const displayName = formatter.courseTitle(ctx)(itemData);
    return _.get(itemData, 'course.photo_url') || `https://ui-avatars.com/api/?name=${displayName}`;
  },

  courseTitle: (ctx) => (itemData) => {
    return _.get(itemData, 'course.name', '');
  },

  courseStartTime: (ctx) => (itemData) => {
    return moment(_.get(itemData, 'start_at', '')).format('hh:mm A');
  },

  courseEndTime: (ctx) => (itemData) => {
    return moment(_.get(itemData, 'end_at', '')).format('hh:mm A');
  },

  courseRangeTime: (ctx) => (itemData) => {
    const startTime = formatter.courseStartTime(ctx)(itemData);
    const endTime = formatter.courseEndTime(ctx)(itemData);

    const duration = _.get(itemData, 'duration', '0') || 0;

    const dayNames = ctx.apply('i18n.t', 'AppCalendar.dayNames');

    const day = moment(_.get(itemData, 'start_at', '')).day();

    if (!startTime || !endTime) return '';

    return `${startTime} - ${endTime} (${durationFormatterText(ctx)(duration * 1000)})`;
  },

  courseTime: (ctx) => (itemData) => {
    const startTime = formatter.courseStartTime(ctx)(itemData);
    const endTime = formatter.courseEndTime(ctx)(itemData);

    const duration = _.get(itemData, 'duration', '0') || 0;

    const dayNames = ctx.apply('i18n.t', 'AppCalendar.dayNames');

    const day = moment(_.get(itemData, 'start_at', '')).day();

    if (!startTime || !endTime) return '';

    return `${_.get(dayNames, day, '')}, ${moment(_.get(itemData, 'start_at', '')).format(
      'DD/MM'
    )}, ${startTime} (${durationFormatterText(ctx)(duration * 1000)})`;
  },

  courseDay: (ctx) => (itemData) => {
    const format_by_locale = _.get(DATE_TIME_CREATED_FORMAT, moment.locale(i18n.locale), 'vi');
    const day = moment(_.get(itemData, 'start_at', '')).format(format_by_locale);
    return ctx.apply('i18n.t', 'RoomChat.DayOf', { day });
  },

  labelMark: (ctx) => (itemData) => {
    const marks = _.groupBy(_.get(itemData, 'marks', []), 'mark_title');
    return _.map(marks, (key, mark_title) => {
      return {
        mark_title,
        items: _.get(marks, mark_title),
      };
    });
  },

  courseDateTxt: (ctx) => (itemData) => {
    const start_at = _.get(itemData, 'start_at', '');
    const month = moment(start_at).month();
    const year = moment(start_at).year();
    const monthNames = ctx.apply('i18n.t', 'AppCalendar.monthNames', []);
    return formatDateDisplay(start_at);
  },

  courseDate: (ctx) => (itemData) => {
    const monthNamesShort = ctx.apply('i18n.t', 'AppCalendar.monthNamesShort');

    const monthIndex = moment(_.get(itemData, 'start_at', '')).month();

    return {
      date: moment(_.get(itemData, 'start_at', '')).date(),
      month: _.get(monthNamesShort, `${monthIndex - 1}`),
    };
  },

  courseFullDate: (ctx) => (itemData) => {
    return _.capitalize(moment(_.get(itemData, 'start_at', '')).locale(i18n.locale).format('LLLL'));
  },

  courseStatus: (ctx) => (itemData) => {
    const $start_at = moment(_.get(itemData, 'start_at', '')).utc();
    const $end_at = moment(_.get(itemData, 'end_at', '')).utc();
    const $now = moment();

    if ($start_at.isAfter($now)) {
      return COURSE_STATUS.NO_STARTED;
    }

    if ($now.isBetween($start_at, $end_at)) {
      return COURSE_STATUS.STARTING;
    }

    return COURSE_STATUS.END;
  },

  courseRangeDisplay: (ctx) => (itemData) => {
    const start_at = moment(_.get(itemData, 'start_at', '')).hour();
    const end_at = moment(_.get(itemData, 'end_at', '')).hour();

    if (!start_at || !end_at) return [0, 0];

    const duration = _.get(itemData, 'duration', '0') || 0;

    const durationHour = normalizeNumber(duration / 3600);

    if (durationHour < 4) {
      return [start_at - 1 > 0 ? start_at - 1 : 0, Math.round(durationHour + 3)];
    }
    return [start_at - 1 > 0 ? start_at - 1 : 0, Math.round(durationHour + 1)];
  },

  getCourseRooms: () => (itemData) => {
    const upcome_rooms = _.get(itemData, 'upcome_rooms', []);
    return upcome_rooms;
  },

  getUnpaidRooms:
    () =>
    (itemData, count = 1) => {
      const upcome_rooms = _.get(itemData, 'upcome_rooms', []);
      const paid_rooms = flattenGet(itemData, 'purchases.purchase.course_rooms');
      const paid_rooms_ids = _.keyBy(paid_rooms, 'course_room_id');

      const rtn = [];
      for (let index = 0; index < upcome_rooms.length; index++) {
        const room = upcome_rooms[index];
        if (room && !_.has(paid_rooms_ids, _.get(room, 'id'))) {
          rtn.push(room);
        }
        if (rtn.length >= count) {
          return rtn;
        }
      }

      return rtn;
    },

  getPassedRoomCondition: () => () => {
    const startOfDay = moment().startOf('day').utc().format(TIMESTAMPTZ_FORMAT);
    return `end_at: { _lte: "${startOfDay}" }`;
  },
  getExactlyPassedRoomCondition: () => () => {
    const startOfDay = moment().utc().format(TIMESTAMPTZ_FORMAT);
    return `end_at: { _lte: "${startOfDay}" }`;
  },

  getUpcomeRoomCondition: () => (date) => {
    const startOfDay = date || moment().startOf('day').utc().format(TIMESTAMPTZ_FORMAT);
    return `_or: [
      {
        start_at: { _gte: "${startOfDay}" }
      },
      {
        start_at: { _lte: "${startOfDay}" }
        end_at: { _gte: "${startOfDay}" }
      }
    ]`;
  },
  getExactlyUpcomeRoomCondition: () => (date) => {
    const startOfDay = date || moment().utc().format(TIMESTAMPTZ_FORMAT);
    return `_or: [
      {
        start_at: { _gte: "${startOfDay}" }
      },
      {
        start_at: { _lte: "${startOfDay}" }
        end_at: { _gte: "${startOfDay}" }
      }
    ]`;
  },
  getTodayRoomCondition: () => (date) => {
    const startOfDay = date || moment().startOf('day').utc().format(TIMESTAMPTZ_FORMAT);
    const endOfDay = moment().endOf('day').utc().format(TIMESTAMPTZ_FORMAT);

    return `start_at: { _lte: "${endOfDay}" }
      ,
      _or: [
      {
        start_at: { _gte: "${startOfDay}" }
      },
      {
        start_at: { _lte: "${startOfDay}" }
        end_at: { _gte: "${startOfDay}" }
      }
    ]`;
  },

  getTodayRoomConditionQuery: () => () => {
    const endOfDay = moment().endOf('day').utc().format(TIMESTAMPTZ_FORMAT);

    return `start_at: { _lte: "${endOfDay}" }
      ,
      _or: [
      {
        start_at: { _gte: $startOfDay }
      },
      {
        start_at: { _lte: $startOfDay }
        end_at: { _gte: $startOfDay }
      }
    ]`;
  },

  getRemainRoomCondition: () => () => {
    const startOfDay = moment().startOf('day').utc().format(TIMESTAMPTZ_FORMAT);
    return `start_at: { _gte: "${startOfDay}" }`;
  },

  roomPayment: () => (item) => {
    const statements = _.get(item, 'transaction.statement');
    const refund = _.find(statements, { name: 'refund' });
    const tuition = _.find(statements, { name: 'tuition' });
    const payment = refund || tuition;
    const amount = formatVND(Math.abs(_.get(payment, 'amount', 0)));
    return {
      name: _.get(payment, 'name', ''),
      amount: _.get(payment, 'name', '') === 'refund' ? `+ ${amount}` : `- ${amount}`,
    };
  },

  roomRegister: (ctx) => (item) => {
    const per_unit = _.get(item, 'courses.0.course.per_unit');
    const course_amount = helpers.flattenGet(item, 'course_rooms.room.course');
    const num_per = _.sumBy(course_amount, 'per_amount');
    return ['per_session', 'session'].includes(per_unit)
      ? ctx.apply('i18n.t', 'Course.Payment.session', { count: num_per })
      : ctx.apply('i18n.t', 'Course.Payment.full_package');
  },

  roomRegisterDisplay: (ctx) => (item) => {
    const course_amount = helpers.flattenGet(item, 'course_rooms.room.course');
    const num_per = _.sumBy(course_amount, 'per_amount');
    const room = _.get(item, 'transaction_purchases.length', 0);
    return ctx.apply('i18n.t', 'Course.Payment.session', { count: `${room}/${num_per}` });
  },

  courseAmount: (ctx) => (item) => {
    const course_amount = helpers.flattenGet(item, 'course_rooms.room.course');
    const price_amount = _.sumBy(course_amount, 'price_amount');
    return formatVND(price_amount);
  },

  normalizeCalendarToEvents: (ctx) => (item) => {
    return _.compact(
      _.flatten(
        _.map(_.keys(item), (day) => {
          return _.map(mergeTimeIfValid(item[day]), (val) => {
            if (!_.get(val, '0')) return null;
            return {
              id: `${day}-${_.get(val, '2')}`,
              dayOfWeek: day,
              start_at: _.get(val, '0'),
              end_at: _.get(val, '1'),
            };
          });
        })
      )
    );
  },

  normalizeEventsToCalendar: (ctx) => (item) => {
    const groupByDay = _.groupBy(item, 'dayOfWeek');

    return _.reduce(
      _.keys(groupByDay),
      (calendar, day) => {
        const items = _.get(groupByDay, day, []);

        _.assign(calendar, {
          [day]: mergeTimeIfValid(
            _.map(items, (item) => [
              _.get(item, 'start_at'),
              _.get(item, 'end_at'),
              _.last(_.split(_.get(item, 'id'), '-')),
            ])
          ),
        });

        return calendar;
      },
      {}
    );
  },

  normalizeEventsToUTCCalendar: (ctx) => (itemData, timezone) => {
    const item = transformUTCEvents(itemData, timezone);
    const groupByDay = _.groupBy(item, 'dayOfWeek');

    return _.reduce(
      _.keys(groupByDay),
      (calendar, day) => {
        const items = _.get(groupByDay, day, []);
        _.assign(calendar, {
          [day]: mergeTimeIfValid(
            _.map(items, (item) => [
              _.get(item, 'start_at'),
              _.get(item, 'end_at'),
              _.last(_.split(_.get(item, 'id'), '-')),
            ])
          ),
        });

        return calendar;
      },
      {}
    );
  },

  markedEvents: (ctx) => (itemData) => {
    return _.reduce(
      itemData,
      (marked, item) => {
        const day = moment(_.get(item, 'start_at')).format('YYYY-MM-DD');

        _.assign(marked, {
          [day]: {
            marked: true,
            customStyles: {
              container: {
                borderRadius: 5,
              },
            },
          },
        });

        return marked;
      },
      {}
    );
  },
};

export default formatter;
