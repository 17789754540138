import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import _ from 'lodash';
import { ResponsiveProps } from '@uz/unitz-components-web/Responsive';

function UnitzBizNavbarListItemLayout() {
  return (
    <DIV>
      {ctx.apply('ctf.renderProps', () => {
        return (
          <div
            gutter={[24, 12]}
            className="flex flex-col lg:items-center lg:flex-row px-0 py-3 lg:py-0 auth-group py-0"
            id="auth-group"
          >
            <DIV>
              {_.get(ctx.apply('ctf.findEntry', { name: ctx.get('sectionData.name') }), 'sections', []).map(
                (col, index) => (
                  <ResponsiveProps xs={{ className: 'mx-0 py-2' }} lg={{ className: 'mx-3 py-0' }}>
                    {(resProps) => (
                      <div {...resProps} key={`${col.name}_${index}`}>
                        {ctx.apply('ctf.renderEntry', { name: col.name }) || null}
                      </div>
                    )}
                  </ResponsiveProps>
                )
              )}
            </DIV>
          </div>
        );
      })}
    </DIV>
  );
}

export default UnitzBizNavbarListItemLayout;
