exports.LANG_MAP_FULL = {
  vi: 'vi_VN',
  en: 'en_US',
  de: 'de',
};

exports.LANG_MAP_LESS = {
  en_US: 'en',
  vi_VN: 'vi',
  vi_US: 'vi',
  vi: 'vi',
  de: 'de',
};
