// import _ from 'lodash';
// import querystring from 'querystring';
// import { useLocation } from '@reach/router';
// import { navigate } from 'gatsby';
// import { routeStore } from '@vl/mod-utils/gatsbyRouteStore';
// import { getCurrentLocaleConfig } from '@uz/mod-translations/utils';
const _ = require('lodash');
const querystring = require('querystring');
const { useLocation } = require('@reach/router');
const { navigate } = require('gatsby');
const { routeStore } = require('@vl/mod-utils/gatsbyRouteStore');
const { getCurrentLocaleConfig } = require('@uz/mod-translations/utils');

const ref = {
  pageContext: {},
  routeState: {},
};

const mergeParams = (currParams, params) => {
  const rtn = _.merge(currParams, params);
  // unset
  _.map(params, (val, key) => {
    if (_.isUndefined(val)) {
      _.unset(rtn, key);
    }
  });
  return rtn;
};

const getWindow = () => {
  if (typeof window !== 'undefined') return window;
  return {};
};

const getParams = (location) => {
  return {
    ...querystring.parse(`${location.search || ''}`.slice(1)),
    ...(() => {
      const state = _.get(location, 'state', {});
      if (_.isPlainObject(state)) return state;
      return {};
    })(),
    ..._.get(ref, 'routeState'),
    ..._.get(ref, 'pageContext.params'),
    ..._.get(getWindow(), 'pageContext.params'),
  };
};

const useGbRoute = (isHook = true) => {
  let location = {};
  try {
    location = isHook && useLocation();
  } catch (err) {}

  // params from location.search
  const params = getParams(location);

  // params from pageContext
  const route = {
    params,
    location,
    navigate,
    to: (pathname, toParams, options) => {
      const mergeParams = _.get(options, 'mergeParams', false);
      let finalParams = mergeParams ? _.merge(params, toParams) : toParams;
      finalParams = querystring.stringify(finalParams);
      finalParams = finalParams ? `?${finalParams}` : '';
      return `${pathname}${finalParams}`;
    },
    toLocale: (pathname, toParams, options) => {
      if (_.isNull(pathname)) return pathname;
      const rtn = route.to(pathname, toParams, options);
      if (_.isNull(rtn)) return rtn;
      const locale = getCurrentLocaleConfig(route);
      return locale.withLangPrefix(rtn);
    },

    redirectUrl: (params, options) => {
      const origin = _.get(options, 'origin', true) ? location.origin : '';
      let { search, hash } = location;
      if (params) {
        const currParams = querystring.parse(`${search}`.slice(1));
        const finalParams = mergeParams(currParams, params);
        search = `${querystring.stringify(finalParams)}`;
        search = search ? `?${search}` : '';
      }
      return `${origin}${location.pathname}${search}${hash || ''}`;
    },

    pushStateParams: (params) => {
      let { search, hash } = location;
      if (params) {
        const currParams = querystring.parse(`${search}`.slice(1));
        const finalParams = mergeParams(currParams, params);
        search = `${querystring.stringify(finalParams)}`;
        search = search ? `?${search}` : '';
      }
      const url = `${location.pathname}${search}${hash || ''}`;
      if (typeof window !== 'undefined') {
        window.history.pushState(`${search}`, '', url);
      }
    },

    navigateExternal: (url) => {
      window.location = url;
    },
    reload: () => {
      window.location.href = window.location.href;
    },
    ...routeStore,

    toUrl: (...args) => {
      let rtn = routeStore.toUrl(...args);
      if (_.isNull(rtn)) return rtn;
      const locale = getCurrentLocaleConfig(route);
      // prefix url with rtn if required
      rtn = locale.withLangPrefix(rtn);
      return rtn;
    },

    setPageContext: (data) => {
      ref.pageContext = data;
    },
    getPageContext: () => ref.pageContext,
    getPageContextParams: () => {
      return _.get(useGbRoute.getGbRoute().getPageContext(), 'params');
    },
    setRouteState: (state) => {
      ref.routeState = state;
    },
    getRouteState: () => ref.routeState,
    updateRouteState: (updater) => (ref.routeState = updater(ref.routeState)),
    getParams: () => {
      const location = typeof window === 'undefined' ? {} : window.location;
      return getParams(location);
    },
  };
  return route;
};

_.assign(useGbRoute, {
  getParams: () => {
    const location = typeof window === 'undefined' ? {} : window.location;
    return getParams(location);
  },
  getGbRoute: (isHook = false) => {
    return useGbRoute(isHook);
  }
});

module.exports = useGbRoute;

// export default useGbRoute;
