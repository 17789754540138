import React from 'react';
import _ from 'lodash';
import { Field } from 'formik-antd';
import { Input } from 'antd';

export const InputText = React.forwardRef(
  ({ name, validate, fast, onChange: $onChange, onBlur: $onBlur, ...restProps }, fRef) => {
    const ref = React.useRef({});
    const onChangeHandle = React.useMemo(
      () =>
        _.debounce((value) => {
          ref.current.form && ref.current.form.setFieldValue(name, value != null ? value.valueOf() : value);
        }, 500),
      []
    );

    return (
      <Field name={name} validate={validate} fast={fast}>
        {({ field: { value, onChange, onBlur }, form }) => {
          ref.current.form = form;
          ref.current.onChange = onChange;
          return (
            <Input
              {...restProps}
              ref={fRef}
              name={name}
              defaultValue={value}
              onChange={(event) => {
                const value = _.get(event, 'target.value');
                onChangeHandle(value);
                $onChange && $onChange(event);
              }}
              onBlur={(event) => {
                onBlur(event);
                $onBlur && $onBlur(event);
              }}
            />
          );
        }}
      </Field>
    );
  }
);

export default InputText;
