import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import { ctx } from '@vl/redata';
import { Row, Col } from 'antd';
import cx from 'classnames';
import Link from '@uz/unitz-components-web/Link';
import LinkPlayer from '@uz/unitz-components-web/LinkPlayer';
import _ from 'lodash';
import gstyles from '@vl/gstyles';

const ObjectVideoLinkLayout = () => {
  return (
    <DIV className="ObjectVideoLinkLayout">
      {ctx.apply('ctf.renderProps', () => {
        return (
          <div className={cx('relative content', ctx.get('sectionData.className'))}>
            <h1 className="text-left text-xl lg:text-2xl mb-4 text-main font-semibold">
              {ctx.get('sectionData.shortText')}
            </h1>

            <Row gutter={[{ xs: 0, md: 24, lg: 48 }, 24]}>
              {_.map(_.take(ctx.get('sectionData.sections', []), 5), (item, index) => {
                const itemData = ctx.apply('ctf.findNode', { name: _.get(item, 'name', '') });
                return (
                  <Col key={`item_${index}`} xs={24}>
                    <LinkPlayer to={_.get(itemData, 'linkHref', '')}>
                      <div className="text-brandb500">{_.get(itemData, 'shortText', '')}</div>
                    </LinkPlayer>
                  </Col>
                );
              })}
            </Row>
            {ctx.get('sectionData.sections.length') > 4 && (
              <Link className="ml-3" to={ctx.apply('routeStore.toLocale', `/${ctx.get('sectionData.className')}`)}>
                <div className="flex items-center">
                  <div className="ml-3 font-semibold">{ctx.apply('i18n.t', 'SeeMore')}</div>
                  <div className="ml-2">
                    {gstyles.icons({ name: 'arrow-right2', size: 16, fill: gstyles.colors.brandb500 })}
                  </div>
                </div>
              </Link>
            )}
          </div>
        );
      })}
    </DIV>
  );
};

export default ObjectVideoLinkLayout;
